<template> 
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 th-loja"
              v-on:click="ordenarPorLoja()"
              :style="{background: (clicado === 'loja') ? 'white' : '',
                      borderRadius: (clicado === 'loja') ? '12px 0 0 0' : ''}"
            >
              <span :style="{ color: (clicado === 'loja') ? 'blue' : ''}" >
                Loja <i v-if="clicado === 'loja'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>  
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('valor')"
              :style="{background: (clicado === 'valor') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'valor') ? 'blue' : '' }">
               Faturamento <i v-if="clicado === 'valor'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('meta')"
              :style="{background: (clicado === 'meta') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'meta') ? 'blue' : '' }">
                Meta <i v-if="clicado === 'meta'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('percmeta')"
              :style="{background: (clicado === 'percmeta') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'percmeta') ? 'blue' : '' }">
                %Meta <i v-if="clicado === 'percmeta'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('percfat')"
              :style="{background: (clicado === 'percfat') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'percfat') ? 'blue' : '' }">
                %Fat. <i v-if="clicado === 'percfat'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('custos')"
              :style="{background: (clicado === 'custos') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'custos') ? 'blue' : '' }">
                Cmv <i v-if="clicado === 'custos'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th> 
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('margem')"
              :style="{background: (clicado === 'margem') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'margem') ? 'blue' : '' }">
                Margem <i v-if="clicado === 'margem'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('itens')"
              :style="{background: (clicado === 'itens') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'itens') ? 'blue' : '' }">
                Itens <i v-if="clicado === 'itens'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('qtdeven')"
              :style="{background: (clicado === 'qtdeven') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'qtdeven') ? 'blue' : '' }">
                QtVen <i v-if="clicado === 'qtdeven'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('pu')"
              :style="{background: (clicado === 'pu') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'pu') ? 'blue' : '' }">
                Pu <i v-if="clicado === 'pu'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('ppu')"
              :style="{background: (clicado === 'ppu') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'ppu') ? 'blue' : '' }">
                %Pu <i v-if="clicado === 'ppu'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('ticket')"
              :style="{background: (clicado === 'ticket') ? 'white' : ''}"
            >
            <span :style="{ color: (clicado === 'ticket') ? 'blue' : '' }">
              Ticket <i v-if="clicado === 'ticket'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
            </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('pa')"
              :style="{background: (clicado === 'pa') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'pa') ? 'blue' : '' }">
                Pa <i v-if="clicado === 'pa'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('pv')"
              :style="{background: (clicado === 'pv') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'pv') ? 'blue' : '' }">
                Pmv <i v-if="clicado === 'pv'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('przcpra')"
              :style="{background: (clicado === 'przcpra') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'przcpra') ? 'blue' : '' }">
                Pmc <i v-if="clicado === 'przcpra'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('prazod')"
              :style="{background: (clicado === 'prazod') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'prazod') ? 'blue' : '' }">
                PrzD <i v-if="clicado === 'prazod'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('valmedio')"
              :style="{background: (clicado === 'valmedio') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'valmedio') ? 'blue' : '' }">
                Valor Médio <i v-if="clicado === 'valmedio'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('cadcli')"
              :style="{background: (clicado === 'cadcli') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'cadcli') ? 'blue' : '' }">
                CliN <i v-if="clicado === 'cadcli'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('cadalt')"
              :style="{background: (clicado === 'cadalt') ? 'white' : '',
                      borderRadius: (clicado === 'cadalt') ? '0 12px 0 0' : ''}"
            >
              <span :style="{ color: (clicado === 'cadalt') ? 'blue' : '' }">
                CliA <i v-if="clicado === 'cadalt'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
          </tr>
        </thead>

        <!--ORDENAÇÃO LOJA-->
        <tbody v-if="this.ordenacao === 'loja'" >
          <tr v-for="(fat,index) in faturamentoLoja" :key="index"
            v-on:click="selecionado(index)" >
            <td >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{fat.loja}}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.valor) }}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.meta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percmeta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percfat) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.custos) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.margem) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.itens }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.qtdeven }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.pu) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.ppu) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.ticket) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.pa) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.pv }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.przcpra }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.prazod }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.valmedio) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.cadcli }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.cadalt }}</h6>
                </div>
              </div>
            </td> 
          </tr> 
        </tbody> 
        <!--FIM ORDENAÇÃO LOJA-->

        <!--ORDENAÇÃO PADRÃO-->
        <tbody v-else-if="this.ordenacao === 'padrao'" >
          <tr v-for="(fat,index) in faturamento" :key="index"
            v-on:click="selecionado(index)" >
            <td >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{fat.loja}}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.valor) }}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.meta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percmeta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percfat) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.custos) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.margem) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.itens }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.qtdeven }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.pu) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.ppu) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.ticket) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.pa) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.pv }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.przcpra }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.prazod }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.valmedio) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.cadcli }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.cadalt }}</h6>
                </div>
              </div>
            </td> 
          </tr> 
        </tbody> 
        <!--FIM ORDERNAÇÃO PADRÃO-->

        <!--ORDENAÇÃO OUTROS-->
        <tbody v-else-if="this.ordenacao === 'outro'" >
          <tr v-for="(fat,index) in faturamentoDecr" :key="index"
            v-on:click="selecionado(index)" >
            <td >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{fat.loja}}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.valor) }}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.meta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percmeta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percfat) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.custos) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.margem) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.itens }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.qtdeven }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.pu) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.ppu) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.ticket) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.pa) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.pv }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.przcpra }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.prazod }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.valmedio) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.cadcli }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ fat.cadalt }}</h6>
                </div>
              </div>
            </td> 
          </tr>
        </tbody> 
        <!--FIM ORDENAÇÃO OUTROS-->

        <!--TOTALIZADOR-------------------------------------------->
        <tbody>
          <tr v-for="tot in totalizador" :key="tot.lojas">
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ tot.lojas }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.fat) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.meta) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tpmeta) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">     </h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.cmv) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tpmar) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ tot.itens }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ tot.qtdeven }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ tot.pu }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tppu) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tticket) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tpa) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tpmv) }}</h6>
                </div>
              </div>
            </td>            
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tpmc) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tprazod) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.tvalmedio) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ tot.cadcli }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ tot.cadalt }}</h6>
                </div>
              </div>
            </td>

          </tr>
        </tbody>
        <!--FIM TOTALIZADOR-->

      </table>
    </div> 
  </div>
</template>

<script> 
import '@fortawesome/fontawesome-free/css/all.css' 

export default {
  name: "GridMarxFaturamento",
  props: {
    faturamento: {
      type: Array,
      required: true
    },
    totalizador: {
      type: Object,
      required: true
    }
  },
  data() {
    return {   
      ordenacao: 'padrao',
      clicado: 'valor',
      faturamentoLoja: '',
      faturamentoDecr: '',
      registroSelecionado: '',
      isMouseOverTitle: false,
    }
  },
  components: {
    //CardVendasClass
  },
  methods: {
    selecionado(index) {
      //console.log("Registro selecionado: "+index)
      this.registroSelecionado = index;
    },
    ordenarPorLoja() {
      this.ordenacao = 'loja'
      this.clicado = 'loja'

      this.faturamentoLoja = [...this.faturamento];

      this.faturamentoLoja.sort((a, b) => {
      let lojaA = a.loja.toLowerCase();
      let lojaB = b.loja.toLowerCase();

      if (lojaA.length < 2) {
        lojaA = lojaA.toString().padStart(2, '0');
      }
      if (lojaB.length < 2) {
        lojaB = lojaB.toString().padStart(2, '0');
      }

      if (lojaA < lojaB) {
        return -1;
      }
      if (lojaA > lojaB) {
        return 1;
      }
      return 0;
    });

    console.log("faturamentoLoja: "+this.faturamentoLoja)
    },
    ordenarDecr(campo) {
      this.ordenacao = 'outro'
      if(campo === 'valor'){
        this.clicado = 'valor'
      }else if (campo === 'meta'){
        this.clicado = 'meta'
      }else if (campo === 'percmeta'){
        this.clicado = 'percmeta'
      }else if (campo === 'percfat'){
        this.clicado = 'percfat'
      }else if (campo === 'custos'){
        this.clicado = 'custos'
      }else if (campo === 'margem'){
        this.clicado = 'margem'
      }else if (campo === 'itens'){
        this.clicado = 'itens'
      }else if (campo === 'qtdeven'){
        this.clicado = 'qtdeven'
      }else if (campo === 'pu'){
        this.clicado = 'pu'
      }else if (campo === 'ppu'){
        this.clicado = 'ppu'
      }else if (campo === 'ticket'){
        this.clicado = 'ticket'
      }else if (campo === 'pa'){
        this.clicado = 'pa'
      }else if (campo === 'pv'){
        this.clicado = 'pv'
      }else if (campo === 'przcpra'){
        this.clicado = 'przcpra'
      }else if (campo === 'prazod'){
        this.clicado = 'prazod'
      }else if (campo === 'valmedio'){
        this.clicado = 'valmedio'
      }else if (campo === 'cadcli'){
        this.clicado = 'cadcli'
      }else if (campo === 'cadalt'){
        this.clicado = 'cadalt'
      }else{
        this.clicado = 'padrao'
      }

      this.faturamentoDecr = [...this.faturamento];


      const elementosFiltrados = [...this.faturamento].filter(fat => fat[campo] !== null && fat[campo] !== 0 
        );
 
      const elementosOrdenados = [...this.faturamento].filter(fat => fat[campo] === null || fat[campo] === 0
        );

      console.log("campo: "+ campo) 
      elementosFiltrados.sort((a, b) => {
      let valorA = parseInt(a[campo]);
      let valorB = parseInt(b[campo]);

      if (valorA > valorB) {
        return -1;
      }
      if (valorA < valorB) {
        return 1;
      }
      return 0;
    });

      this.faturamentoDecr = elementosFiltrados.concat(elementosOrdenados); 
    },
    formataData(dataOriginal){
      let data = new Date(dataOriginal);
      let dia = data.getDate();
      let mes = data.getMonth() + 1;
      let ano = data.getFullYear();

      let dataFormatada = dia + '/' + mes + '/' + ano;
      return dataFormatada
    },
  }, 
  watch: {
    faturamento: {
      immediate: true,
      handler() {
        this.ordenacao = 'padrao';
        this.clicado = 'valor';
        },
    },
  },
  computed: {
    totalValor() {
      let total = 0;
      for (const fat of this.faturamento) {
        total += parseFloat(fat.valor);
      }
      return 'R$ ' + this.formataValor(total);
    },
    totalItens(){
      let total = 0;
      for (const fat of this.faturamento) {
        total += parseFloat(fat.itens);
      }
      return  total;
    }, 
    totalTotal() {
      let total = 0;
      for (const fat of this.faturamento) {
        total += parseFloat(fat.total);
      }
      return 'R$ ' + this.formataValor(total);
    },
  },  
  created(){
  },
};
</script>
<style>
  .fas.fa-trash-alt{
    color: red;
  }

  .sombra-destacada {
    box-shadow: 0 1 5px rgba(230, 228, 233, 0.5); /* Substitua a cor azul pelo valor de sombra desejado */
  }
  .modal-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 15px rgba(1, 1, 1, 1.5);
    border-radius: 30%;
  }

  .pointer-cursor {
    cursor: pointer;
  }

  /*.th-loja{
    position: sticky;
    left: 0; 
    z-index: 1;
  }
  .hidden {
    display: none;
  }*/
</style>
