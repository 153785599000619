<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <!--<div class="col-12">
        <navbar is-blur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow" btn-background="bg-gradient-success"
          :dark-mode="true" />
      </div>-->
    </div>
  </div>
  <main class="main-content main-content-bg mt-0">
    <div class="page-header min-vh-100" style="background-image: url('https://raroapi.ddns.net/files/imagens/fundo.jpg');
          ">
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2 mb-3">Login</h5>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <div class="text-center text-muted mb-4">
                  <small>Entre com as suas credenciais</small>
                </div>
                <form role="form" class="text-start" @submit.prevent="submit">
                  <div class="mb-3">
                    <argon-input id="email" type="email" placeholder="Email" aria-label="Email" v-model="email" />
                  </div> 
                  <div class="mb-3">
                    <argon-input id="password" type="password" placeholder="Password" aria-label="Password"
                      v-model="password" />
                  </div>
                  <argon-switch id="rememberMe" name="rememberMe">
                    Lembrar
                  </argon-switch>

                  <div class="text-center">
                    <argon-button color="success" variant="gradient" full-width class="my-4 mb-2">Login</argon-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
//import Navbar from "@/examples/PageLayout/Navbar.vue";
import API_URL from '../../../api.js'
import AppFooter from "@/examples/PageLayout/Footer.vue";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";  
import { mapMutations } from "vuex";
import { encryptData } from '../../../api.js';

export default {
  name: "SigninBasic",
  data() {
    return {
      email: '',
      password: '',
      token: '',
      cliente: '',
      erro: '',
    }
  },
  components: {
    //Navbar,
    AppFooter,
    ArgonInput,
    ArgonSwitch,
    ArgonButton, 
    //    ArgonAlert,
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
  },
  beforeUnmount() {
    console.log("unmount");
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
  },
  methods: {
    submit() {
      this.$swal({
        title: "...aguarde...",
        didOpen: () => {
          this.$swal.showLoading();
          //.post('https://api.raro-srv.com/api/token', { "email": this.email, "password": this.password, "device_name": "web" })
          this.$http
            .post(API_URL+'token', { "email": this.email, "password": this.password, "device_name": "raroapp" })
            .then((response) => {
              this.token = response.data.token;
              this.cliente = response.data.cliente;
              this.erro = response.data.erro;
              if (response.data.erro) {
                this.$swal({
                  title: "Falha no login!",
                  text: "Dados incorretos!",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn bg-gradient-success",
                  },
                  buttonsStyling: false,
                });
              } else {
                this.$swal.close();
                localStorage.token = encryptData(this.token);
                localStorage.cliente = this.cliente;
                this.$router.push('/dashboards/dashboard-default');
              }
            })
        }
      })
    },
    ...mapMutations(["toggleDefaultLayout"]),
    //...mapMutations([console.log("submit")]),
  },
};
</script>
