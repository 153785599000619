<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >

    <!--INICIO PRINCIPAL------------------------------------------------------------------->
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item 
                :to="{ name: 'Default' }"
                mini-icon="P"
                text="Principal"
                @click.prevent="toggleNavigationOnMobile"
              />
      </li>
    <!--FIM PRINCIPAL-->

      <!--INICIO PESQUISAS---------------------------------------------------------->
      <li class="mt-3 nav-item">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="$store.state.isRTL ? 'me-4' : 'ms-2'"
        >
          Pesquisas
        </h6>
      </li>
      <!--FIM PESQUISAS-->
      
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Vendas"
          :class="getRoute() === 'pages' ? 'active' : ''"
        >
          <template #icon>
            <!--<i class="ni ni-ungroup text-warning text-sm opacity-10"></i>-->
            <i class="ni ni-archive-2 text-success text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->              
              <sidenav-item
                :to="{ name: 'Vendas Periodo' }"
                mini-icon="P"
                text="Período"
                @click.prevent="toggleNavigationOnMobile"
              />
              <sidenav-item
                :to="{ name: 'Vendas Margem x Faturamento' }"
                mini-icon="M"
                text="Margem x Faturamento"
                @click.prevent="toggleNavigationOnMobile"
              />
              <sidenav-item
                :to="{ name: 'Vendas Meta Corrigida' }"
                mini-icon="C"
                text="Meta Corrigida"
                @click.prevent="toggleNavigationOnMobile"
              />
              <sidenav-item
                :to="{ name: 'Vendas Departamento' }"
                mini-icon="D"
                text="Departamento"
                @click.prevent="toggleNavigationOnMobile"
              />
              <!---->
             <!-- <sidenav-collapse-item
                refer="VendasVendedores"
                mini-icon="P"
                text="Vendedor"
              >
                <template #nav-child-item>
                  <sidenav-item
                    :to="{ name: 'Vendedor Desempenho' }"
                    mini-icon="P"
                    text="Desempenho"
                    @click.prevent="toggleNavigationOnMobile"
                  />
                  <sidenav-item
                    :to="{ name: 'Vendedor Detalhe' }"
                    mini-icon="T"
                    text="Detalhe"
                  />
                  <sidenav-item
                    :to="{ name: 'Vendedor Resumo' }"
                    mini-icon="A"
                    text="resumo"
                  />
                </template>
              </sidenav-collapse-item>-->
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="pagesExamples"
          nav-text="Vendedores"
          :class="getRoute() === 'pages' ? 'active' : ''"
        >
          <template #icon>
            <!--<i class="ni ni-ungroup text-warning text-sm opacity-10"></i>-->
            <i class="fas fa-person text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->              
              <sidenav-item
                :to="{ name: 'Vendedores Desempenho' }"
                mini-icon="D"
                text="Desempenho"
                @click.prevent="toggleNavigationOnMobile"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <div>
        <hr class="mt-0 horizontal dark" />
      </div>
      <!--FIM PESQUISAS------------------------------------------------------->

      <!--FIM DOCS---------------------------------------------------------------------------------->
    </ul>
  </div>

  
  
  <div class="mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: 'Precisa de ajuda?',
        description: 'contate-nos',
        links: [
          {
            label: 'Whatsapp',
            route:
              'https://api.whatsapp.com/send?phone=551938164963&text=Ol%C3%A1%20%40rarosoftware%2C%20gostaria%20de%20um%20atendimento.',
            color: 'success',
            icon: 'whatsapp'
          }
        ]
      }"
    />
  </div>
  <div class="mt-3 sidenav-footer">
    <sidenav-card @click="showMessage('warning-message-and-confirmation','Fazer Logout','Deseja sair do sistema?')"
       
       :card="{
        title: '',
        description: '',
        links: [
          {
            label: 'Logout',
            //route: 'https://api.whatsapp.com/send?phone=551938164963&text=Ol%C3%A1%20%40rarosoftware%2C%20gostaria%20de%20um%20atendimento.',
            color: 'warning'
          }
        ]
      }"
    />
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import SidenavCard from "./SidenavCard.vue";
import { mapMutations } from "vuex";
//import SidenavCollapseItem from "./SidenavCollapseItem.vue";

/*importar ícone de whatsapp e logou para colocar no Label
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas);*/

export default {
  name: "SidenavList",
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCard,
//    SidenavCollapseItem
  },
  created() {
    this.minNav;
    this.$emit('navbar-minimize', this.navbarMinimize);
  },
  beforeUpdate() {
    this.toggleNavigationOnMobile();
  },
  methods: {
    ...mapMutations(["navbarMinimize","navbarMaximize", "toggleConfigurator"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 768) {
        this.navbarMinimize();
      }
    },
    minimizaSideNav(){
      this.navbarMinimize();
    },
    maximizaSideNav(){
      this.navbarMaximize();
    },
    logout() {
      console.log('fazer logout');
      
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    showMessage(type,title,text) {
      if (type === "basic") {
        this.$swal({
          title: title,
          type: type,
        });
      } else if (type === "success-message") {
        this.$swal({
          icon: "success",
          title: title,
          text: text,
          type: type,
        });
      } else if (type === "custom-html") {
        this.$swal({
          icon: "info",
          title: "<strong>HTML <u>example</u></strong>",
          html:
            "You can use <b>bold text</b>, " +
            '<a href="//sweetalert2.github.io">links</a> ' +
            "and other HTML tags",
          type: type,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: '<i class="fa fa-thumbs-up"></i> Great!',
          confirmButtonAriaLabel: "Thumbs up, great!",
          cancelButtonText: '<i class="fa fa-thumbs-down"></i>',
          cancelButtonAriaLabel: "Thumbs down",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      } else if (type === "input-field") {
        this.$swal({
          title: "Submit your Github username",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Look up",
          showLoaderOnConfirm: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
          preConfirm: (login) => {
            return fetch(`//api.github.com/users/${login}`)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(response.statusText);
                }
                return response.json();
              })
              .catch((error) => {
                this.$swal.showValidationMessage(`Request failed: ${error}`);
              });
          },
          allowOutsideClick: () => !this.$swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: `${result.value.login}'s avatar`,
              imageUrl: result.value.avatar_url,
            });
          }
        });
      } else if (type === "title-and-text") {
        this.$swal({
          title: "Sweet!",
          text: "Modal with a custom image.",
          imageUrl: "https://unsplash.it/400/200",
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
        });
      } else if (type === "auto-close") {
        let timerInterval;
        this.$swal({
          title: "Auto close alert!",
          html: "I will close in <b></b> milliseconds.",
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            this.$swal.showLoading();
            const b = this.$swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = this.$swal.getTimerLeft();
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        });
      } else if (type === "warning-message-and-confirmation") {
        this.toggleNavigationOnMobile()
        //this.$store.commit('minimizeSidenav'); 
        this.$swal({
          title: title,
          text: text,
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          
          if (result.isConfirmed) {
            delete localStorage.token,
            delete localStorage.cliente,



            this.$router.push('/authentication/signin/basic');
          } else{
            this.maximizaSideNav();
          }
        });
      } else if (type === "warning-message-and-cancel") {
        this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) {
            this.$swal({
              title: "Deleted!",
              text: "Your file has been deleted.",
              icon: "success",
              customClass: {
                confirmButton: "btn bg-gradient-success",
              },
              buttonsStyling: false,
            });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === this.$swal.DismissReason.cancel
          ) {
            this.$swal.dismiss;
          }
        });
      } else if (type === "rtl-language") {
        this.$swal({
          title: "هل تريد الاستمرار؟",
          icon: "question",
          iconHtml: "؟",
          confirmButtonText: "نعم",
          cancelButtonText: "لا",
          showCancelButton: true,
          showCloseButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        });
      }
    },    
  }
};
</script>
