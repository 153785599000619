<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Pesquisar Orçamentos</h6>
          <hr class="my-4 horizontal dark" />

          <!--GRID DE ORÇAMENTOS -->
          <div class="row mt-4">
            <div class="col-12">
              <grid-orcamento :orcamentos="orcamentos"/>
            </div>
          </div>
 
          <div class="mt-4 d-flex justify-content-end">
            <!--<button type="button" name="button" class="m-0 btn btn-light" v-on:click="clearForm()">
              Cancelar
            </button>-->
            <button v-on:click="novo()"
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
            >
            Novo
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/l10n/pt.js"; 
import GridOrcamento from "./components/GridOrcamento.vue"; 
import API_URL from "../../api.js"
import Choices from "choices.js";
import Quill from "quill"; 
import {mask} from 'vue-the-mask';

export default {
  name: "NewProject", 
  directives: {
    mask
  },
  components: {
    GridOrcamento,
    //flatPickr,
  },
  data() {
    return {  
      novoOrca: '',
      orcamentos: [
        {}
      ],
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y'
      },
    };
  }, 
  methods: {    
    novo(){
      //console.log(decryptData(localStorage.token));
      fetch(API_URL+'orcamento', {
            method: 'POST',
            headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.token 
                     },
            //body: JSON.stringify(data)
           })
      .then(response => {
        if (!response.ok) {
          throw new Error('Erro ao enviar os dados');
        }
        return response.json();
      })
      .then(responseData => { 
        localStorage.orca = responseData.data.orc_cod
        this.$router.push('/venda/Orcaincluir');
        console.log(localStorage.orca);
      })
      .catch(error => { 
        console.error(error);
      });
    },
    clearForm() {
    const defaultData = {  
      orcamentos: [
        {}
      ],
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y'
      },
      // adicione aqui outras variáveis do seu estado que precisam ser limpas
    }
    Object.assign(this.$data, defaultData);
    }, 
  },
  created() { 
    this.$http
      .get(API_URL+'orcamento',{headers: {
            "Content-Type": "application/json",
              Authorization: "Bearer "+localStorage.getItem('token')}}) 
      .then((response) => { 
        this.orcamentos = response.data.data 
      })
      .catch(e => { 
        console.log(e+" - Problemas para localizar orçamentos!")
      }); 
  },
  mounted() {
    if (document.getElementById("editor")) {
      new Quill("#editor", {
        theme: "snow", // Specify theme in configuration
      });
    }

    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
        allowHTML: true,
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true,
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true,
          },
          {
            value: "Three",
            label: "Label Three",
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
  computed: { 
  }
}
</script>  

<style>
  .form-control.error{
    border-color: red;
  }

  .form-control.datetimepicker.error{
    border-color: red;
  }
</style>
 