<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Lcto
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Data
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Hora
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Vend.
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Valor
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Itens
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Exc.
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="orca in orcamentosOrdenados" :key="orca.orc_cod">
            <td >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{orca.orc_cod}}</h6>
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{this.formataData(orca.orc_data)}}</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{orca.orc_hora}}</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{orca.orc_vend}}</span>
            </td>
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{'R$ ' + this.formataValor(orca.orc_vlr)}}</h6>
                </div>
              </div>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{orca.orc_itens}}</span>
            </td>
            <td class="align-middle text-center">
              <button class="m-0 btn btn-white" 
                @click="excluir(orca.orc_cod)">
                <i class="fas fa-trash-alt"></i>
              </button>
              <!--<span class="text-secondary text-sm">
                <i class="fas fa-trash-alt"></i> 
              </span>-->
            </td>
          </tr> 
        </tbody>
      </table>
    </div>
  </div>
</template>

<script> 
import '@fortawesome/fontawesome-free/css/all.css' 
import API_URL from "../../../api.js"
//import axios from 'axios'

export default {
  name: "GridOrcamento",
  props: {
    orcamentos: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  methods: {
    excluir(id) {
        this.$swal({
          title: 'Exclusão de Orçamento',
          text: 'Tem certeza que deseja excluir esse lançamento?',
          type: 'warning-message-and-confirmation',
          showCancelButton: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          reverseButtons: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.isConfirmed) { 
            console.log("lançamento: "+id)
            fetch(API_URL+'orcamento/'+id, {
            method: 'DELETE',
            headers: {
                      'Content-Type': 'application/json',
                      'Authorization': 'Bearer ' + localStorage.token 
                     },
            //body: JSON.stringify(data)
           }).then(response => {
              if (!response.ok) {
                throw new Error('Erro ao enviar os dados');
              }
              return response.json();
            })
            .then(responseData => {
              this.$swal({
                icon: "success",
                title: "",
                confirmButtonText: "Ok",
                text: "Exclusão efetuada com sucesso!",
                type: "success-message",
              }).then((result) => {
                if (result.isConfirmed) {
                    console.log(responseData.data);
                    location.reload();
                  }
                })
            })
            .catch(error => {
              // Trate o erro
              console.error(error);
            });
          } 
        });
    },
    formataData(dataOriginal){
      let data = new Date(dataOriginal);
      let dia = data.getDate();
      let mes = data.getMonth() + 1;
      let ano = data.getFullYear();

      let dataFormatada = dia + '/' + mes + '/' + ano;
      return dataFormatada
    },
    formataValor(valorOriginal){
      let valorFormatado = Number(valorOriginal).toFixed(2);
      valorFormatado = valorFormatado.replace('.', ',');
      return valorFormatado
    }
  },
  computed: {
    orcamentosOrdenados() {
      // Faça uma cópia do array "orcamentos" para não modificar o original
      const orcamentosCopy = [...this.orcamentos];

    // Ordena o array com base em "o_data" em ordem decrescente
    // Em caso de datas iguais, ordena por "o_lcto" em ordem decrescente
    orcamentosCopy.sort((a, b) => {
      if (a.orc_data > b.orc_data) {
        return -1; // Mantém a ordem decrescente da data
      } else if (a.orc_data < b.orc_data) {
        return 1; // Inverte a ordem para a.data > b.data
      } else {
        // Em caso de datas iguais, ordena por "o_lcto" em ordem decrescente
        return b.orc_cod - a.orc_cod;
      }
    });

      return orcamentosCopy;
    }
  }, 
  data() {
    return {
    };
  },
};
</script>
<style>
  .fas.fa-trash-alt{
    color: red;
  }
</style>
