<template> 
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 th-loja"
              v-on:click="ordenarPorLoja()"
              :style="{background: (clicado === 'loja') ? 'white' : '',
                      borderRadius: (clicado === 'loja') ? '12px 0 0 0' : ''}"
            >
              <span :style="{ color: (clicado === 'loja') ? 'blue' : ''}" >
                Loja <i v-if="clicado === 'loja'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>  
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('fatura')"
              :style="{background: (clicado === 'fatura') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'fatura') ? 'blue' : '' }">
               Fat. até Ontem <i v-if="clicado === 'fatura'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('metames')"
              :style="{background: (clicado === 'metames') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'metames') ? 'blue' : '' }">
                Meta Mensal <i v-if="clicado === 'metames'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('percmeta')"
              :style="{background: (clicado === 'percmeta') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'percmeta') ? 'blue' : '' }">
                %Ating <i v-if="clicado === 'percmeta'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('faltameta')"
              :style="{background: (clicado === 'faltameta') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'faltameta') ? 'blue' : '' }">
                Falta para Bater <i v-if="clicado === 'faltameta'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('percfalta')"
              :style="{background: (clicado === 'percfalta') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'percfalta') ? 'blue' : '' }">
                %Falta <i v-if="clicado === 'percfalta'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th> 
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('fathoje')"
              :style="{background: (clicado === 'fathoje') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'fathoje') ? 'blue' : '' }">
                Fatura Hoje <i v-if="clicado === 'fathoje'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('perchoje')"
              :style="{background: (clicado === 'perchoje') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'perchoje') ? 'blue' : '' }">
                %Hoje <i v-if="clicado === 'perchoje'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia1')"
              :style="{background: (clicado === 'dia1') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia1') ? 'blue' : '' }">
                Meta Hoje <i v-if="clicado === 'dia1'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th  v-show="validaMes(formataData(datah,1) )"
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia2')"
              :style="{background: (clicado === 'dia2') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia2') ? 'blue' : '' }">
                Meta Amanhã <i v-if="clicado === 'dia2'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <!--carrega aqui-->
            <th v-show="validaMes(formataData(datah,2) )"
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia3')"
              :style="{background: (clicado === 'dia3') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'dia3') ? 'blue' : '' }">
                {{ this.formataData(this.datah,2)  }} <i v-if="clicado === 'dia3'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th  v-show="validaMes(formataData(datah,3) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia4')"
              :style="{background: (clicado === 'dia4') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia4') ? 'blue' : '' }">
                {{ this.formataData(this.datah,3)  }} <i v-if="clicado === 'dia4'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th  v-show="validaMes(formataData(datah,4) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia5')"
              :style="{background: (clicado === 'dia5') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia5') ? 'blue' : '' }">
                {{ this.formataData(this.datah,4)  }} <i v-if="clicado === 'dia5'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,5) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia6')"
              :style="{background: (clicado === 'dia6') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia6') ? 'blue' : '' }">
                {{ this.formataData(this.datah,5)  }} <i v-if="clicado === 'dia6'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,6) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia7')"
              :style="{background: (clicado === 'dia7') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia7') ? 'blue' : '' }">
                {{ this.formataData(this.datah,6)  }} <i v-if="clicado === 'dia7'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th  v-show="validaMes(formataData(datah,7) )"
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia8')"
              :style="{background: (clicado === 'dia8') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia8') ? 'blue' : '' }">
                {{ this.formataData(this.datah,7)  }} <i v-if="clicado === 'dia8'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,8) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia9')"
              :style="{background: (clicado === 'dia9') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia9') ? 'blue' : '' }">
                {{ this.formataData(this.datah,8)  }} <i v-if="clicado === 'dia9'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,9) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia10')"
              :style="{background: (clicado === 'dia10') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia10') ? 'blue' : '' }">
                {{ this.formataData(this.datah,9)  }} <i v-if="clicado === 'dia10'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,10) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia11')"
              :style="{background: (clicado === 'dia11') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia11') ? 'blue' : '' }">
                {{ this.formataData(this.datah,10)  }} <i v-if="clicado === 'dia11'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th  v-show="validaMes(formataData(datah,11) )"
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia12')"
              :style="{background: (clicado === 'dia12') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia12') ? 'blue' : '' }">
                {{ this.formataData(this.datah,11)  }} <i v-if="clicado === 'dia12'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,12) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia13')"
              :style="{background: (clicado === 'dia13') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia13') ? 'blue' : '' }">
                {{ this.formataData(this.datah,12)  }} <i v-if="clicado === 'dia13'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,13) )"
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia14')"
              :style="{background: (clicado === 'dia14') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia14') ? 'blue' : '' }">
                {{ this.formataData(this.datah,13)  }} <i v-if="clicado === 'dia14'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,14) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia15')"
              :style="{background: (clicado === 'dia15') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia15') ? 'blue' : '' }">
                {{ this.formataData(this.datah,14)  }} <i v-if="clicado === 'dia15'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,15) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia16')"
              :style="{background: (clicado === 'dia16') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia16') ? 'blue' : '' }">
                {{ this.formataData(this.datah,15)  }} <i v-if="clicado === 'dia16'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,16) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia17')"
              :style="{background: (clicado === 'dia17') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia17') ? 'blue' : '' }">
                {{ this.formataData(this.datah,16)  }} <i v-if="clicado === 'dia17'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,17) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia18')"
              :style="{background: (clicado === 'dia18') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia18') ? 'blue' : '' }">
                {{ this.formataData(this.datah,17)  }} <i v-if="clicado === 'dia18'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,18) )"
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia19')"
              :style="{background: (clicado === 'dia19') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia19') ? 'blue' : '' }">
                {{ this.formataData(this.datah,18)  }} <i v-if="clicado === 'dia19'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,19) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia20')"
              :style="{background: (clicado === 'dia20') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia20') ? 'blue' : '' }">
                {{ this.formataData(this.datah,19)  }} <i v-if="clicado === 'dia20'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,20) )"
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia21')"
              :style="{background: (clicado === 'dia21') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia21') ? 'blue' : '' }">
                {{ this.formataData(this.datah,20)  }} <i v-if="clicado === 'dia21'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,21) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia22')"
              :style="{background: (clicado === 'dia22') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia22') ? 'blue' : '' }">
                {{ this.formataData(this.datah,21)  }} <i v-if="clicado === 'dia22'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,22) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia23')"
              :style="{background: (clicado === 'dia23') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia23') ? 'blue' : '' }">
                {{ this.formataData(this.datah,22)  }} <i v-if="clicado === 'dia23'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,23) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia24')"
              :style="{background: (clicado === 'dia24') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia24') ? 'blue' : '' }">
                {{ this.formataData(this.datah,23)  }} <i v-if="clicado === 'dia24'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,24) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia25')"
              :style="{background: (clicado === 'dia25') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia25') ? 'blue' : '' }">
                {{ this.formataData(this.datah,24)  }} <i v-if="clicado === 'dia25'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,25) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia26')"
              :style="{background: (clicado === 'dia26') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia26') ? 'blue' : '' }">
                {{ this.formataData(this.datah,25)  }} <i v-if="clicado === 'dia26'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,26) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia27')"
              :style="{background: (clicado === 'dia27') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia27') ? 'blue' : '' }">
                {{ this.formataData(this.datah,26)  }} <i v-if="clicado === 'dia27'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,27) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia28')"
              :style="{background: (clicado === 'dia28') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia28') ? 'blue' : '' }">
                {{ this.formataData(this.datah,27)  }} <i v-if="clicado === 'dia28'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,28) )"
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia29')"
              :style="{background: (clicado === 'dia29') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia29') ? 'blue' : '' }">
                {{ this.formataData(this.datah,28)  }} <i v-if="clicado === 'dia29'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,29) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia30')"
              :style="{background: (clicado === 'dia30') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia30') ? 'blue' : '' }">
                {{ this.formataData(this.datah,29)  }} <i v-if="clicado === 'dia30'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-show="validaMes(formataData(datah,30) )" 
              v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dia31')"
              :style="{background: (clicado === 'dia31') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'dia31') ? 'blue' : '' }">
                {{ this.formataData(this.datah,30)  }} <i v-if="clicado === 'dia31'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
          </tr>
        </thead>

        <!--ORDENAÇÃO LOJA-->
        <tbody v-if="this.ordenacao === 'loja'" >
          <tr v-for="(fat,index) in faturamentoLoja" :key="index"
            v-on:click="selecionado(index)" >
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{fat.loja}}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.fatura) }}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.metames) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percmeta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.faltameta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percfalta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.fathoje) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.perchoje) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia1) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,1) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia2) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,2) )" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia3) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,3) )" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia4) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,4) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia5) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,5) )"> 
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia6) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,6) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia7) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,7) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia8) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,8) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia9) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,9) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia10) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,10) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia11) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,11) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia12) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,12) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia13) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,13) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia14) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,14) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia15) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,15) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia16) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,16) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia17) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,17) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia18) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,18) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia19) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,19) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia20) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,20) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia21) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,21) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia22) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,22) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia23) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,23) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia24) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,24) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia25) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,25) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia26) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,26) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia27) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,27) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia28) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,28) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia29) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,29) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia30) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,30) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia31) }}</h6>
                </div>
              </div>
            </td> 
          </tr> 
        </tbody> 
        <!--FIM ORDENAÇÃO LOJA-->

        <!--ORDENAÇÃO PADRÃO-->
        <tbody v-else-if="this.ordenacao === 'padrao'" >
          <tr v-for="(fat,index) in faturamento" :key="index"
            v-on:click="selecionado(index)" >
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{fat.loja}}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.fatura) }}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.metames) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percmeta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.faltameta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percfalta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.fathoje) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.perchoje) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia1) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,1) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia2) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,2) )" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia3) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,3) )" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia4) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,4) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia5) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,5) )"> 
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia6) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,6) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia7) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,7) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia8) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,8) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia9) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,9) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia10) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,10) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia11) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,11) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia12) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,12) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia13) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,13) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia14) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,14) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia15) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,15) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia16) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,16) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia17) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,17) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia18) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,18) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia19) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,19) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia20) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,20) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia21) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,21) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia22) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,22) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia23) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,23) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia24) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,24) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia25) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,25) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia26) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,26) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia27) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,27) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia28) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,28) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia29) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,29) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia30) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,30) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia31) }}</h6>
                </div>
              </div>
            </td> 
          </tr>
        </tbody> 
        <!--FIM ORDERNAÇÃO PADRÃO-->

        <!--ORDENAÇÃO OUTROS-->
        <tbody v-else-if="this.ordenacao === 'outro'" >
          <tr v-for="(fat,index) in faturamentoDecr" :key="index"
            v-on:click="selecionado(index)" >
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{fat.loja}}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.fatura) }}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.metames) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percmeta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.faltameta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{this.formataValor(fat.percfalta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.fathoje) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.perchoje) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia1) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,1) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia2) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,2) )" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia3) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,3) )" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia4) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,4) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia5) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,5) )"> 
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia6) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,6) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia7) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,7) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia8) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,8) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia9) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,9) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia10) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,10) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia11) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,11) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia12) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,12) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia13) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,13) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia14) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,14) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia15) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,15) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia16) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,16) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia17) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,17) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia18) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,18) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia19) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,19) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia20) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,20) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia21) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,21) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia22) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,22) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia23) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,23) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia24) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,24) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia25) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,25) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia26) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,26) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia27) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,27) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia28) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,28) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia29) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,29) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia30) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,30) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.dia31) }}</h6>
                </div>
              </div>
            </td> 
          </tr> 
        </tbody> 
        <!--FIM ORDENAÇÃO OUTROS-->

        <!--TOTALIZADOR-------------------------------------------->
        <tbody>
          <tr v-for="tot in totalizador" :key="tot.lojas">
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{tot.lojas}}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.fatura) }}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.metames) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{this.formataValor(tot.pating) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.faltameta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{this.formataValor(tot.pfalta) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.fathoje) }}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.perchoje) }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d1) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,1) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d2) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,2) )" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d3) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,3) )" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d4) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,4) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d5) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,5) )"> 
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d6) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,6) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d7) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,7) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d8) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,8) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d9) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,9) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d10) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,10) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d11) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,11) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d12) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,12) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d13) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,13) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d14) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,14) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d15) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,15) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d16) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,16) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d17) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,17) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d18) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,18) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d19) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,19) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d20) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,20) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d21) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,21) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d22) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,22) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d23) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,23) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d24) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,24) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d25) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,25) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d26) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,26) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d27) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,27) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d28) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,28) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d29) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,29) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d30) }}</h6>
                </div>
              </div>
            </td> 
            <td v-show="validaMes(formataData(datah,30) )">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ this.formataValor(tot.d31) }}</h6>
                </div>
              </div>
            </td> 
          </tr> 
          
        </tbody>
        <!--FIM TOTALIZADOR-->

      </table>
    </div> 
  </div>
</template>

<script> 
import '@fortawesome/fontawesome-free/css/all.css' 
import { format } from 'date-fns';

export default {
  name: "GridMetaCorrigida",
  props: {
    faturamento: {
      type: Array,
      required: true
    },
    totalizador: {
      type: Object,
      required: true
    }
  },
  data() {
    return {   
      ordenacao: 'padrao',
      clicado: 'fathoje',
      faturamentoLoja: '',
      faturamentoDecr: '',
      isMouseOverTitle: false,
      registroSelecionado: '',
      datai: format(new Date(),'dd/MM/yyyy'),
      datah: new Date()
    }
  },
  components: {
    //CardVendasClass
  },
  methods: {
    selecionado(index) {
      //console.log("Registro selecionado: "+index)
      this.registroSelecionado = index;
    },
    ordenarPorLoja() {
      this.ordenacao = 'loja'
      this.clicado = 'loja'

      this.faturamentoLoja = [...this.faturamento];

      this.faturamentoLoja.sort((a, b) => {
      let lojaA = a.loja.toLowerCase();
      let lojaB = b.loja.toLowerCase();

      if (lojaA.length < 2) {
        lojaA = lojaA.toString().padStart(2, '0');
      }
      if (lojaB.length < 2) {
        lojaB = lojaB.toString().padStart(2, '0');
      }

      if (lojaA < lojaB) {
        return -1;
      }
      if (lojaA > lojaB) {
        return 1;
      }
      return 0;
    });

    console.log("faturamentoLoja: "+this.faturamentoLoja)
    },
    ordenarDecr(campo) {
      this.ordenacao = 'outro'
      if(campo === 'fatura'){
        this.clicado = 'fatura'
      }else if (campo === 'metames'){
        this.clicado = 'metames'
      }else if (campo === 'percmeta'){
        this.clicado = 'percmeta'
      }else if (campo === 'faltameta'){
        this.clicado = 'faltameta'
      }else if (campo === 'percfalta'){
        this.clicado = 'percfalta'
      }else if (campo === 'fathoje'){
        this.clicado = 'fathoje'
      }else if (campo === 'perchoje'){
        this.clicado = 'perchoje'
      }else if (campo === 'dia1'){
        this.clicado = 'dia1'
      }else if (campo === 'dia2'){
        this.clicado = 'dia2'
      }else if (campo === 'dia3'){
        this.clicado = 'dia3'
      }else if (campo === 'dia4'){
        this.clicado = 'dia4'
      }else if (campo === 'dia5'){
        this.clicado = 'dia5'
      }else if (campo === 'dia6'){
        this.clicado = 'dia6'
      }else if (campo === 'dia7'){
        this.clicado = 'dia7'
      }else if (campo === 'dia8'){
        this.clicado = 'dia8'
      }else if (campo === 'dia9'){
        this.clicado = 'dia9'
      }else if (campo === 'dia10'){
        this.clicado = 'dia10'
      }else if (campo === 'dia11'){
        this.clicado = 'dia11'
      }else if (campo === 'dia12'){
        this.clicado = 'dia12'
      }else if (campo === 'dia13'){
        this.clicado = 'dia13'
      }else if (campo === 'dia14'){
        this.clicado = 'dia14'
      }else if (campo === 'dia15'){
        this.clicado = 'dia15'
      }else if (campo === 'dia16'){
        this.clicado = 'dia16'
      }else if (campo === 'dia17'){
        this.clicado = 'dia17'
      }else if (campo === 'dia18'){
        this.clicado = 'dia18'
      }else if (campo === 'dia19'){
        this.clicado = 'dia19'
      }else if (campo === 'dia20'){
        this.clicado = 'dia20'
      }else if (campo === 'dia21'){
        this.clicado = 'dia21'
      }else if (campo === 'dia22'){
        this.clicado = 'dia22'
      }else if (campo === 'dia23'){
        this.clicado = 'dia23'
      }else if (campo === 'dia24'){
        this.clicado = 'dia24'
      }else if (campo === 'dia25'){
        this.clicado = 'dia25'
      }else if (campo === 'dia26'){
        this.clicado = 'dia26'
      }else if (campo === 'dia27'){
        this.clicado = 'dia27'
      }else if (campo === 'dia28'){
        this.clicado = 'dia28'
      }else if (campo === 'dia29'){
        this.clicado = 'dia29'
      }else if (campo === 'dia30'){
        this.clicado = 'dia30'
      }else if (campo === 'dia31'){
        this.clicado = 'dia31'
      }else{
        this.clicado = 'padrao'
      }

      this.faturamentoDecr = [...this.faturamento];


      const elementosFiltrados = [...this.faturamento].filter(fat => fat[campo] !== null && fat[campo] !== 0 
        );
 
      const elementosOrdenados = [...this.faturamento].filter(fat => fat[campo] === null || fat[campo] === 0
        );

      console.log("campo: "+ campo) 
      elementosFiltrados.sort((a, b) => {
      let valorA = parseInt(a[campo]);
      let valorB = parseInt(b[campo]);

      if (valorA > valorB) {
        return -1;
      }
      if (valorA < valorB) {
        return 1;
      }
      return 0;
    });

      this.faturamentoDecr = elementosFiltrados.concat(elementosOrdenados); 
    },
    validaMes(data){
      //DATA ATUAL
      const dataAtual = new Date(); // Obtém a data atual
      const dataFormatada = `${dataAtual.getDate().toString().padStart(2, '0')}/${(dataAtual.getMonth() + 1)
          .toString().padStart(2, '0')}/${dataAtual.getFullYear()}`;
      const dataI = dataFormatada.toString()
      let primeiraBarraI =  dataI.indexOf("/");
      let semDiaI = dataI.slice(primeiraBarraI+1,dataI.length)
      let segundaBarraI = semDiaI.indexOf("/");
      let mes1 = semDiaI.slice(0,segundaBarraI)
      
      //DATA PARAMETRO
      const datae = data.toString()
      let primeiraBarra =  datae.indexOf("/");
      let semDia = datae.slice(primeiraBarra+1,datae.length)
      let segundaBarra = semDia.indexOf("/");
      let mes2 = semDia.slice(0,segundaBarra)
      
      const mesAtual = parseInt(mes1);    
      const mesPametro = parseInt(mes2);    

      if(mesPametro > mesAtual){
        /*console.log("")
        console.log("false - ") 
        console.log("Data parametro: "+data)
        console.log("Data atual: "+dataFormatada)*/
        return false
      } else{
        /*console.log("")
        console.log("true - ") 
        console.log("Data parametro: "+data)
        console.log("Data atual: "+dataFormatada)*/
        return true
      }
    },
    formataData(data,soma){
      const timestamp = data.getTime();

      // Adicionar o número de dias especificado em "soma" (em milissegundos)
      const novoTimestamp = timestamp + soma * 24 * 60 * 60 * 1000;

      // Criar uma nova data a partir do novo timestamp
      const dataNova = new Date(novoTimestamp);

      // Formatar a nova data para o formato desejado (dd/MM/yyyy)
      const dataFormatada = `${dataNova.getDate().toString().padStart(2, '0')}/${(dataNova.getMonth() + 1).toString().padStart(2, '0')}/${dataNova.getFullYear()}`;

      return dataFormatada;
    },
  }, 
  watch: {
    faturamento: {
      immediate: true,
      handler() {
        this.ordenacao = 'padrao';
        this.clicado = 'fathoje';
        },
    },
  },
  computed: {
    totalValor() {
      let total = 0;
      for (const fat of this.faturamento) {
        total += parseFloat(fat.valor);
      }
      return 'R$ ' + this.formataValor(total);
    },
    totalItens(){
      let total = 0;
      for (const fat of this.faturamento) {
        total += parseFloat(fat.itens);
      }
      return  total;
    }, 
    totalTotal() {
      let total = 0;
      for (const fat of this.faturamento) {
        total += parseFloat(fat.total);
      }
      return 'R$ ' + this.formataValor(total);
    },
  },  
  created(){
  },
};
</script>
<style>
  .fas.fa-trash-alt{
    color: red;
  }

  .sombra-destacada {
    box-shadow: 0 1 5px rgba(230, 228, 233, 0.5); /* Substitua a cor azul pelo valor de sombra desejado */
  }
  .modal-overlay {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    box-shadow: 0 0 15px rgba(1, 1, 1, 1.5);
    border-radius: 30%;
  }

  .pointer-cursor {
    cursor: pointer;
  }

  /*.th-loja{
    position: sticky;
    left: 0; 
    z-index: 1;
  }
  .hidden {
    display: none;
  }*/
</style>
