<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0 ">
        <thead class="thead-light">
          <tr>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              
            >
            <!--v-on:click="ordenarPorLoja()"-->
              Loja
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              
            >
            <!--v-on:click="ordenarDecr('itens')"-->
              Itens
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 vlr-custom-width"
              
            >
            <!--v-on:click="ordenarDecr('valor')"-->
              Valor
            </th>
            <th 
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              Det.
            </th>
          </tr>
        </thead>

        <!--ordenacao padrão-->
        <tbody v-if="ordenacao === 'padrao'">
          <template v-for="(venda, index) in vendas" :key="venda.loja">
            <tr :class="{ 'registro-aberto': expandedRow === index }">
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ venda.loja }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ venda.itens }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ this.formataValor(venda.valor) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <button class="btn btn-link" v-on:click="pesquisar(index)">
                  <i :class="expandedRow === index ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </button>
              </td>
            </tr>

            <tr v-if="expandedRow === index">
              <td colspan="2">
                <div class="collapse show" :key="'expanded-' + index">
                 <KeepAlive>
                   <card-vendas-class :vendasclass="vendasclass"></card-vendas-class>
                 </KeepAlive>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <!--FIM ORDENAÇÃO PADRAO-->

        <!--ORDENAÇÃO POR LOJA-->
        <tbody v-if="ordenacao === 'loja'">
          <template v-for="(venda, index) in vendasLoja" :key="venda.loja">
            <tr :class="{ 'registro-aberto': expandedRow === index }">
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ venda.loja }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ venda.itens }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ this.formataValor(venda.valor) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <button class="btn btn-link" v-on:click="pesquisar(index)">
                  <i :class="expandedRow === index ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </button>
              </td>
            </tr>

            <tr v-if="expandedRow === index">
              <td colspan="4">
                <div class="collapse show" :key="'expanded-' + index">
                 <KeepAlive>
                   <card-vendas-class :vendasclass="vendasclass"></card-vendas-class>
                 </KeepAlive>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <!--FIM ORDENAÇÃO POR LOJA-->

        
        <!--ORDENAÇÃO POR OUTRO-->
        <tbody v-else-if="ordenacao === 'outro'">
          <template v-for="(venda, index) in vendasDecr" :key="venda.loja">
            <tr :class="{ 'registro-aberto': expandedRow === index }">
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ venda.loja }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ venda.itens }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ this.formataValor(venda.valor) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <button class="btn btn-link" v-on:click="pesquisar(index)">
                  <i :class="expandedRow === index ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
                </button>
              </td>
            </tr>

            <tr v-if="expandedRow === index">
              <td colspan="4">
                <div class="collapse show" :key="'expanded-' + index">
                 <KeepAlive>
                   <card-vendas-class :vendasclass="vendasclass"></card-vendas-class>
                 </KeepAlive>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
        <!--FIM ORDENAÇÃO POR LOJA-->

        <!--TOTALIZADORES--------------------------->
        <tbody>
          <tr>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">TOTAL</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ totalItens }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color: 'black'}">{{ totalValor }}</h6>
                </div>
              </div>
            </td>
            <td>
              <button class="btn btn-link" v-on:click="pesquisarTotal()">
                <i :class="expandedTotal === true ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></i>
              </button>
              </td>
          </tr>

          <tr v-if="expandedTotal === true">
              <td colspan="2" class="text-center align-items-center">
                <div class="collapse show text-center">
                 <KeepAlive>
                   <card-vendas-class-total :vendasclasstotal="vendasclasstotal" class="text-center"></card-vendas-class-total>
                 </KeepAlive>
                </div>
              </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css'
import CardVendasClass from "./CardVendasClass.vue"
import CardVendasClassTotal from "./CardVendasClassTotal.vue"
import API_URL from "../../../api.js"
import { decryptData } from '../../../api.js';

export default {
  name: "GridVendasPeriodo",
  props: {
    vendas: {
      type: Array,
      required: true
    },
    datai: {
      type: String,
      required: true
    },
    dataf: {
      type: String,
      required: true
    },
  },
  components: {
   CardVendasClass,
   CardVendasClassTotal
  },
  data() {
    return {
      expandedRow: -1,
      expandedTotal: false,
      lojap: '',
      vendasclass: [{}],
      vendasclasstotal: [{}],
      registroAberto: -1,
      cache: {},
      cacheTotal: {},
      ordenacao: 'padrao',
      vendasLoja: '',
      vendasDecr: '',
      lastDatai: '',
      lastDataf: '',
    };
  },
  methods: {
    toggleExpandedTotal() {
      this.expandedTotal = !this.expandedTotal;
    },
    pesquisarTotal() {
      this.$swal({
        didOpen: () => {
          this.$swal.showLoading();

          const cacheKey = `${this.datai}-${this.dataf}`; // Chave do cache com base nas datas de pesquisa

          // Verifique se os dados já estão em cache
          if (this.cacheTotal[cacheKey]) {
            this.vendasclasstotal = this.cacheTotal[cacheKey];
            this.$swal.close();
            this.toggleExpandedTotal();
          } else {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + decryptData(localStorage.token));

            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };

            fetch(API_URL + 'vendasclasslj?datai=' + this.formataData(this.datai) + '&dataf=' + this.formataData(this.dataf), requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log("result total:" + result.data), this.vendasclasstotal = result.data;
                this.$swal.close();

                // Armazene os dados em cache usando as datas como chave
                this.cacheTotal[cacheKey] = result.data;

                this.toggleExpandedTotal();
              })
              .catch(error => console.log('error', error));
          }
        }
      })
    },
    pesquisar(index) {
      if (this.expandedRow === index) {
        this.expandedRow = -1;
        this.registroAberto = -1;
      } else {
        this.expandedRow = index;
        this.lojap = this.vendas[index].loja;
        this.registroAberto = index;

        const cacheKey = `${this.datai}-${this.dataf}`;
        const cacheData = this.cache[cacheKey];

        // Verifique se as datas de pesquisa são diferentes das armazenadas no cache
        if (cacheData && this.lastDatai === this.datai && this.lastDataf === this.dataf && index in cacheData) {
          this.vendasclass = cacheData[index]; // Use o cache existente
        } else {
          this.$swal.showLoading();

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer " + decryptData(localStorage.token));

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          fetch(API_URL + 'vendasclasslj?datai=' + this.formataData(this.datai) + '&dataf=' + this.formataData(this.dataf) + "&loja=" + this.lojap, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result), this.$swal.close();

              // Armazene os dados em cache usando as datas como chave
              if (!this.cache[cacheKey]) {
                this.cache[cacheKey] = {};
              }
              this.cache[cacheKey][index] = result.data;
              this.lastDatai = this.datai; // Atualize as últimas datas de pesquisa
              this.lastDataf = this.dataf;

              this.vendasclass = result.data
              this.toggleExpandedTotal();
            })
            .catch(error => console.log('error', error));
        }
      }
    },
    clearCache() {
      this.cache = {};
      this.cacheTotal = {}; 
    },
    ordenarPorLoja() {
      this.ordenacao = 'loja'

      this.vendasLoja = [...this.vendas];

      this.vendasLoja.sort((a, b) => {
      let lojaA = a.loja.toLowerCase();
      let lojaB = b.loja.toLowerCase();

      if (lojaA.length < 2) {
        lojaA = lojaA.toString().padStart(2, '0');
      }
      if (lojaB.length < 2) {
        lojaB = lojaB.toString().padStart(2, '0');
      }

      if (lojaA < lojaB) {
        return -1;
      }
      if (lojaA > lojaB) {
        return 1;
      }
      return 0;
    });

    console.log("vendasLoja: "+this.vendasLoja)
    },    
    ordenarDecr(campo) {
      if(this.ordenacao !== 'outro'){
        this.ordenacao = 'outro'
      }else{
        this.ordenacao = 'padrao'
      }

      this.vendasDecr = [...this.vendas];

      console.log("campo: "+ campo) 
      this.vendasDecr.sort((a, b) => {
        let lojaA = parseInt(a[campo]);
        let lojaB = parseInt(b[campo]);

        if (lojaA > lojaB) {
          return -1;
        }
        if (lojaA < lojaB) {
          return 1;
        }
        return 0;
      }); 

      console.log("vendasDecr: " + this.vendasDecr);
    },
    toggleExpandedRow(index) {
      this.expandedRow = this.expandedRow === index ? -1 : index;
    },
    formataData(data){
      const datae = data.toString()
      let primeiraBarra =  datae.indexOf("/");
      let dia = datae.slice(0,primeiraBarra)
      let semDia = datae.slice(primeiraBarra+1,datae.length)
      let segundaBarra = semDia.indexOf("/");
      let mes = semDia.slice(0,segundaBarra)
      let ano = semDia.slice(segundaBarra+1,semDia.length)
      return  `${ano}-${mes}-${dia}`;
    },
    formataValor(valorOriginal) {
      let valorFormatado = Number(valorOriginal).toFixed(2);
      valorFormatado = valorFormatado.replace('.', ',');
      const numero = parseFloat(valorFormatado.replace(",", "."));
      const numeroFormatado = numero.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return numeroFormatado;
    }
  },
  computed: {
    totalValor() {
      let total = 0;
      for (const venda of this.vendas) {
        total += parseFloat(venda.valor);
      }
      return this.formataValor(total);
    },
    totalItens() {
      let total = 0;
      for (const venda of this.vendas) {
        total += parseFloat(venda.itens);
      }
      return total;
    }
  }
};
</script>

<style>
  .fas.fa-trash-alt {
    color: red;
  }

  .custom-width {
  width: 10px; /* Defina o valor desejado para a largura */
  } 

  .vlr-custom-width {
  width: 70px; /* Defina o valor desejado para a largura */
  } 

  /*.registro-aberto {
    background-color: rgb(250, 248, 248);
    background: linear-gradient(to bottom,rgb(250, 248, 248),white)
  }*/
</style>
