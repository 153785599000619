<template> 
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr > 
            <th
            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
            >
              Descrição
            </th>
            <th
            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
            >
              Total
            </th>
            <th
            class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
            >
              Margem
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(venda, index) in vendasclasstotal" :key="index" > 
            <!--<td >
              <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{venda.loja}}</h6>
                </div>
              </div>
            </td>-->
            <td >
              <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{venda.descricao}}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ this.formataValor(venda.total)}}</h6>
                </div>
              </div>
            </td> 
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ this.formataValor(venda.margem)}}</h6>
                </div>
              </div>
            </td> 
          </tr> 
        </tbody>
      </table>
    </div> 
  </div>
</template>

<script> 
import '@fortawesome/fontawesome-free/css/all.css' 
//import API_URL from "../../../api.js"
//import axios from 'axios'

export default {
  name: "CardVendasClassTotal",
  props: {
    vendasclasstotal: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  methods: {
    ordenarPorLoja() {
      const vendasCopy = [...this.vendasclass];

      vendasCopy.sort((a, b) => {
        let lojaA = a.loja.toLowerCase();
        let lojaB = b.loja.toLowerCase();

        if(lojaA.length < 2){
          lojaA = (lojaA).toString().padStart(2, '0')
        } 
        if(lojaB.length < 2){
          lojaB = (lojaB).toString().padStart(2, '0')
        } 

        if (lojaA < lojaB) {
          return -1;
        }
        if (lojaA > lojaB) {
          return 1;
        }
        return 0;
      });

      return vendasCopy;
    },
    formataData(dataOriginal){
      let data = new Date(dataOriginal);
      let dia = data.getDate();
      let mes = data.getMonth() + 1;
      let ano = data.getFullYear();

      let dataFormatada = dia + '/' + mes + '/' + ano;
      return dataFormatada
    },
    formataValor(valorOriginal){
      let valorFormatado = Number(valorOriginal).toFixed(2);
      valorFormatado = valorFormatado.replace('.', ',');
      const numero = parseFloat(valorFormatado.replace(",", "."));
      const numeroFormatado = numero.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return numeroFormatado
    }
  }, 
  computed: {
    totalValor() {
      let total = 0;
      for (const venda of this.vendasclass) {
        total += parseFloat(venda.valor);
      }
      return  this.formataValor(total);
    },
    totalItens(){
      let total = 0;
      for (const venda of this.vendasclass) {
        total += parseFloat(venda.itens);
      }
      return  total;
    }, 
  }, 
  data() {
    return {
    };
  },
};
</script>
<style>
  .fas.fa-trash-alt{
    color: red;
  }

  .detalhe-pesquisa{
    background-color: rgb(250, 248, 248)
  }
</style>
