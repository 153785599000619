<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Cadastro de Fornecedor</h6>
          <hr class="my-4 horizontal dark" />

          <!--PRIMEIRA LINHA-->
          <div class="mt-2 row">
            <div class="col-4">
              <label for="projectName" class="form-label">CNPJ *</label>
              <input id="cnpj-input" type="text" class="form-control" v-mask="'##.###.###/####-##'" v-model="cnpj"
                 @blur="validaCNPJ" v-bind:disabled="isDisabled" 
                 v-bind:class="inputCnpj"
                 />
            </div>
            <div class="col-8">
              <label for="projectName" class="form-label">Razão Social *</label>
              <input id="razao-input" type="text" class="form-control" v-model="razao" ref="meuInputCnpj"
                 v-bind:class="inputRazao" @blur="updateInputRazao"
                 />
            </div>
          </div>

          <!--SEGUNDA LINHA-->
          <div class="mt-2 row"> 
            <div class="col-2">
              <label for="projectName" class="form-label">CEP</label>
              <input id="cep-input" type="text" class="form-control" v-mask="'#####-###'" v-model="cep" />
            </div>
            <div class="col-2">
              <label class="form-label">Logr.</label>
                <select id="logradouro-input" class="form-control" v-model="selectedLogradouro">
                  <option value="nullo">- -</option> 
                  <option value="rua">R.</option>
                  <option value="avenida">Av.</option>
                  <option value="urbano">Al.</option> 
                  <option value="praca">Prc.</option>
                  <option value="quadra">Qdr.</option>
                  <option value="bloco">Blc.</option> 
                </select>
            </div> 
            <div class="col-2">
              <label class="form-label">UF *</label>
                <select id="ufendereco-input" class="form-control" v-model="selectedUfendereco"
                  v-bind:class="inputUfEnd" @blur="updateInputUfEnd">
                  <option value="">- -</option>
                  <option value="AC">AC</option>
                  <option value="AL">AL</option>
                  <option value="AM">AM</option>
                  <option value="AP">AP</option>
                  <option value="BA">BA</option>
                  <option value="CE">CE</option>
                  <option value="DF">DF</option>
                  <option value="ES">ES</option>
                  <option value="GO">GO</option>
                  <option value="MA">MA</option>
                  <option value="MG">MG</option>
                  <option value="MS">MS</option>
                  <option value="MT">MT</option>
                  <option value="PA">PA</option>
                  <option value="PE">PE</option>
                  <option value="PI">PI</option>
                  <option value="PR">PR</option>
                  <option value="RJ">RJ</option>
                  <option value="RN">RN</option>
                  <option value="RO">RO</option>
                  <option value="RR">RR</option>
                  <option value="RS">RS</option>
                  <option value="SC">SC</option>
                  <option value="SE">SE</option>
                  <option value="SP">SP</option>
                  <option value="TO">TO</option> 
                </select>
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">Cidade *</label>
              <input id="cidade-input" type="text" class="form-control" v-model="cidade"
                v-bind:class="inputCidade" @blur="updateInputCidade"/>
            </div>
          </div>

          <!--TERCEIRA LINHA-->
          <div class="mt-2 row">
            <div class="col-6">
              <label for="projectName" class="form-label">Endereço</label>
              <input id="endereco-input" type="text" class="form-control" v-model="endereco"/>
            </div>
            <div class="col-4">
              <label for="projectName" class="form-label">Bairro</label>
              <input id="bairro-input" type="text" class="form-control" v-model="bairro"/>
            </div>
            <div class="col-2">
              <label for="projectName" class="form-label">Nº</label>
              <input id="numero-input" type="text" class="form-control" v-model="numresidencial"/>
            </div> 
          </div>

          <!--QUARTA LINHA-->
          <div class="mt-2 row">
            <div class="col-3">
              <label for="projectName" class="form-label">Telefone</label>
              <input id="telefone-input" type="text" class="form-control" v-mask="'(##)####-####'" v-model="telefone" />
            </div>
            <div class="col-3">
              <label for="projectName" class="form-label">Celular</label>
              <input id="celular-input" type="text" class="form-control" v-mask="'(##)#########'" v-model="celular" />
            </div>
            <div class="col-6">
              <label for="projectName" class="form-label">E-mail</label>
              <input id="email-input" type="text" class="form-control" v-model="email"/>
            </div>
          </div>

          <!-- QUINTA LINHA -->
          <div class="mt-2 row">
            <div class="col-5">
              <label for="projectName" class="form-label">Fantasia</label>
              <input id="complemento-input" type="text" class="form-control" v-model="fantasia"/>
            </div>
            <div class="col-7">
              <label for="projectName" class="form-label">Complemento</label>
              <input id="complemento-input" type="text" class="form-control" v-model="complemento"/>
            </div>
          </div>

          <!-- SEXTA LINHA -->
          <div class="mt-4 d-flex justify-content-end">
            <button type="button" name="button" class="m-0 btn btn-light" v-on:click="clearForm()">
              Cancelar
            </button>
            <button v-on:click="gravar()"
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
            >
            {{ inclusao ? 'Adicionar Cadastro' : 'Alterar Cadastro' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Dropzone from "dropzone";
import Choices from "choices.js";
import Quill from "quill"; 
import {mask} from 'vue-the-mask'

export default {
  name: "NewProject", 
  directives: {
    mask
  },
  components: {
  },
  data() {
    return {
      inclusao: true,
      isDisabled: false,
      inputTouchedCnpj: false,
      inputTouchedRazao: false,
      inputTouchedDtNasc: false,
      inputTouchedSexo: false,
      inputTouchedTpEnd: false,
      inputTouchedUfEnd: false,
      inputTouchedCidade: false,
      erro: false,
      razao: "",
      cnpj: "",
      tipocad: "F",
      tipo: "C",
      stcnpj: "A",
      selectedSex: '',
      orgaoexped: '',
      uforgaoexped:'',
      selectedTipoend: '',
      cep: '',
      selectedUfendereco: '',
      endereco:'',
      cidade: "",
      complemento: '',
      numresidencial: '',
      fantasia: '',
      bairro: '',
      selectedLogradouro: '',
      telefone: '',
      celular: '',
      email: '',
      rg: "",
      date: "", 
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y'
      },
    };
  },
  filters: {
    upperCase(value) {
      return value.toUpperCase();
    }
  },
  watch: {
    razao: function(newValor) {
      this.razao = newValor == null ? " " : newValor.toUpperCase();
    },
    cidade: function(newValor) {
      this.cidade = newValor == null ? " " : newValor.toUpperCase();
    },
    bairro: function(newValor) {
      this.bairro = newValor == null ? " " : newValor.toUpperCase();
    },
    complemento: function(newValor) {
      this.complemento = newValor == null ? " " : newValor.toUpperCase();
    },
    fantasia: function(newValor) {
      this.fantasia=  newValor == null ? " " : newValor.toUpperCase();
    },
    orgaoexped: function(newValor) {
      this.orgaoexped = newValor == null ? " " : newValor.toUpperCase();
    },
    uforgaoexped: function(newValor) {
      this.uforgaoexped = newValor == null ? " " : newValor.toUpperCase();
    },
  },
  methods: { 
    checkInput() {
      this.cnpj = this.cnpj !== '' ? this.cnpj.trim() : this.cnpj;
    },
    temCaracterEspecial(str) {
      var regex = /[^\w\s]/gi;
      return regex.test(str);
    },
    formataDataLaravel(data){
      let dataStr = data;
      let partesData = dataStr.split('/');
      let dataObj = new Date(partesData[2], partesData[1] - 1, partesData[0]);
      let dataISO = dataObj.toISOString();
      let dataFinal = dataISO.slice(0, 10);
      return dataFinal
    },
    formataDataVue(data){
      const datae = new Date(data);
      // Extraia o dia, mês e ano
      const dia = datae.getDate().toString().padStart(2, '0');
      const mes = (datae.getMonth() + 1).toString().padStart(2, '0');
      const ano = datae.getFullYear().toString();
      // Formate a data como "dd/mm/aaaa"
      const dataFormatada = `${dia}/${mes}/${ano}`;
      return dataFormatada
    },
    formataTelefone(data){
      let novoFone
      if(data !== null){
        novoFone = data.includes(" ") ? data.trim() : data
      }
      return novoFone
    },
    formataCelular(data){
      let celStr 
      let novaStr
      if(data !== null){
        let CelSemIfem = celStr.includes("-") ? celStr.replace("-","") : celStr
        novaStr = CelSemIfem.includes(" ") ? CelSemIfem.trim() : CelSemIfem
      }
      return novaStr
      //const novaStr = str.replace(/-/g, "");
    },
    focusInputCnpj() {
      this.$refs.meuInputCnpj.focus();
    },
    camposObrigatorios(){
          this.cnpj === '' ? this.inputTouchedCnpj = true : this.inputTouchedCnpj = false  
          this.razao === '' ? this.inputTouchedRazao = true : this.inputTouchedRazao = false 
          //this.date === '' ? this.inputTouchedDtNasc = true :  this.inputTouchedDtNasc = false 
          this.selectedSex === '' ? this.inputTouchedSexo = true : this.inputTouchedSexo = false 
          this.selectedTipoend === '' ? this.inputTouchedTpEnd = true : this.inputTouchedTpEnd = false 
          this.selectedUfendereco === '' ? this.inputTouchedUfEnd = true : this.inputTouchedUfEnd = false 
          this.cidade === '' ? this.inputTouchedCidade = true : this.inputTouchedCidade = false 
          if(this.inputTouchedCnpj == true || this.inputTouchedRazao == true 
          || this.inputTouchedSexo == true || this.inputTouchedTpEnd == true
          || this.inputTouchedUfEnd == true  || this.inputTouchedCidade == true){
            return true
          } else{
            return false
          }
    },
    gravar(){
        if(this.camposObrigatorios()){
          alert("Tem campo(s) não preenchido(s) ou incorreto(s)!")
        } else {
          if (this.inclusao === true && this.erro === false){
            console.log("Modo inclusao: "+this.inclusao) 
            this.$http
              .post('http://10.12.0.49:8000/api/client/',{
                            for_cnpj: this.cnpj,
                            cli_tippes: this.tipocad,
                            cli_tipo: this.tipo,
                            for_rso: this.razao,
                            cli_tpend: this.selectedTipoend,
                            cli_rg: this.rg,
                            cli_dtn: this.formataDataLaravel(this.date),
                            cli_sexo: this.selectedSex,
                            cli_uf: this.ufendereco,
                            cli_ufoe: this.uforgaoexped,
                            cli_oe: this.orgaoexped,
                            cli_cid: this.cidade,
                            cli_bai: this.bairro,
                            cli_num: this.numresidencial.toString(),
                            cli_cep: this.cep,
                            for_fantasia: this.fantasia,
                            cli_email: this.email,
                            cli_compl: this.complemento,
                            cli_log: this.selectedLogradouro,
                            cli_fone: this.telefone !== '' ? this.formataTelefone(this.telefone) : this.telefone,
                            cli_cel: this.celular !== '' ? this.formataCelular(this.celular) : this.celular
                            }, 
                            {headers: {"Content-Type": "application/json",
                            Authorization: "Bearer "+localStorage.getItem('token')}}) 
                    .then((response) => {
                      this.clearForm();
                      if(!response.data.erro){
                        console.log("STATUS INCLUSÃO: "+JSON.stringify(response.data))
                        alert("Cadastro realizado com sucesso!")
                      }else{
                        console.log("erro na inclusão: "+response)
                      }
    
                    })
                    .catch(e => {
                      console.log(e+" - Problemas na inclusão do cadastro!")
                      this.clearForm();
                    });
          } else if (this.inclusao === false && this.erro === false) {
            console.log("Modo alteração")
            this.$http
              .put('http://10.12.0.49:8000/api/client/'+this.cnpj,{
                            cli_tippes: this.tipocad,
                            cli_tipo: this.tipo,
                            for_rso: this.razao,
                            cli_tpend: this.selectedTipoend,
                            cli_rg: this.rg,
                            cli_dtn: this.date,
                            cli_sexo: this.selectedSex,
                            cli_uf: this.selectedUfendereco,
                            cli_ufoe: this.uforgaoexped,
                            cli_oe: this.orgaoexped,
                            cli_cid: this.cidade,
                            cli_bai: this.bairro,
                            cli_num: this.numresidencial.toString(),
                            cli_cep: this.cep,
                            for_fantasia: this.fantasia,
                            cli_email: this.email,
                            cli_compl: this.complemento,
                            cli_log: this.selectedLogradouro,
                            cli_fone: this.telefone !== '' ? this.formataTelefone(this.telefone) : this.telefone,
                            cli_cel: this.celular !== '' ? this.formataCelular(this.celular) : this.celular
                            }, {headers: {"Content-Type": "application/json",
                            Authorization: "Bearer "+localStorage.getItem('token')}}) 
                    .then((response) => {
                      if(response){
                        this.clearForm(); 
                        alert("Cadastro alterado com sucesso!")
                      }else{
                        console.log("STATUS ALTERAÇÃO: "+JSON.stringify(response.data))
                      }
                    })
                    .catch(e => {
                      this.clearForm();
                      console.log(e+" - Problemas na alteração do cadastro!")
                    });
          } else if( this.erro === true){
            alert("Erro no preenchimento do formulário")
          }else{
            alert("Problemas para Gravar!!!")
          }
        }
    },
    validaCNPJ() {
      if (this.cnpj !== null && this.cnpj !== '' && this.cnpj.length == 18 ) {
        this.isDisabled = true
        this.inputTouchedCNPJ = false
        this.$swal({
        title: "consultando CNPJ...",
        didOpen: () => {
          this.$swal.showLoading();
          this.focusInputCnpj();
          this.$http
            //.get('https://api.raro-srv.com/api/client/'+this.cnpj,{headers: {
            .get('http://10.12.0.49:8000/api/client/'+this.cnpj,{headers: {
                  "Content-Type": "application/json",
                    Authorization: "Bearer "+localStorage.getItem('token')}}) 
            .then((response) => {
                const res = response.data
                if(res && res.data.length !== 0){
                  this.inclusao = false
                  this.razao = res.data.for_rso
                  const dataFormatada = this.formataDataVue(res.data.cli_dtn);
                 
                    //SELECTED SEXO
                 if(res.data.cli_sexo == 'M'){
                    this.selectedSex = "M"                    
                  }else if  (res.data.cli_sexo == 'F') {
                    this.selectedSex = "F"
                  }else if (res.data.cli_sexo == null) {
                    this.selectedSex = ""
                  } else {
                    this.selectedSex = "O"
                  }

                  //SELECTED TIPO ENDERECO
                  if(res.data.cli_tpend == 'U'){
                    this.selectedTipoend = "U"                    
                  }else if (res.data.cli_tpend == 'R') {
                    this.selectedTipoend = "R"
                  } else {
                    this.selectedTipoend = ""
                  }

                  //SELECTED LOGRADOURO
                  if(res.data.cli_log == 'R.'){
                    this.selectedLogradouro = "R."                    
                  }else if  (res.data.cli_log == 'Av.') {
                    this.selectedLogradouro = "Av."
                  }else if (res.data.cli_log == 'Qdr.') {
                    this.selectedLogradouro = "Qdr."
                  }else if (res.data.cli_log == 'Blc.') {
                    this.selectedLogradouro = "Blc."
                  }else if (res.data.cli_log == 'Prc.') {
                    this.selectedLogradouro = "Prc."
                  } else {
                    this.selectedLogradouro = ""
                  }

                  //SELECTED UNIDADE FEDERATIVA
                  if(res.data.cli_uf == 'AC'){
                    this.selectedUfendereco = "AC"                    
                  }else if  (res.data.cli_uf == 'AL') {
                    this.selectedUfendereco = "AL"
                  }else if (res.data.cli_uf == "AM") {
                    this.selectedUfendereco = "AM"
                  }else if (res.data.cli_uf == "AP") {
                    this.selectedUfendereco = "AP"
                  }else if (res.data.cli_uf == "BA") {
                    this.selectedUfendereco = "BA"
                  }else if (res.data.cli_uf == "CE") {
                    this.selectedUfendereco = "CE"
                  }else if (res.data.cli_uf == "DF") {
                    this.selectedUfendereco = "DF"
                  }else if (res.data.cli_uf == "ES") {
                    this.selectedUfendereco = "ES"
                  }else if (res.data.cli_uf == "GO") {
                    this.selectedUfendereco = "GO"
                  }else if (res.data.cli_uf == "MA") {
                    this.selectedUfendereco = "MA"
                  }else if (res.data.cli_uf == "MG") {
                    this.selectedUfendereco = "MG"
                  }else if (res.data.cli_uf == "MS") {
                    this.selectedUfendereco = "MT"
                  }else if (res.data.cli_uf == "PA") {
                    this.selectedUfendereco = "PA"
                  }else if (res.data.cli_uf == "PB") {
                    this.selectedUfendereco = "PB"
                  }else if (res.data.cli_uf == "PE") {
                    this.selectedUfendereco = "PE"
                  }else if (res.data.cli_uf == "PI") {
                    this.selectedUfendereco = "PI"
                  }else if (res.data.cli_uf == "PR") {
                    this.selectedUfendereco = "PR"
                  }else if (res.data.cli_uf == "RJ") {
                    this.selectedUfendereco = "RJ"
                  }else if (res.data.cli_uf == "RN") {
                    this.selectedUfendereco = "RN"
                  }else if (res.data.cli_uf == "RO") {
                    this.selectedUfendereco = "RO"
                  }else if (res.data.cli_uf == "RR") {
                    this.selectedUfendereco = "RR"
                  }else if (res.data.cli_uf == "RS") {
                    this.selectedUfendereco = "RS"
                  }else if (res.data.cli_uf == "SC") {
                    this.selectedUfendereco = "SC"
                  }else if (res.data.cli_uf == "SE") {
                    this.selectedUfendereco = "SE"
                  }else if (res.data.cli_uf == "SP") {
                    this.selectedUfendereco = "SP"
                  }else if (res.data.cli_uf == "TO") {
                    this.selectedUfendereco = "TO"
                  } else {
                    this.selectedUfendereco = ""
                  }

                  this.rg = res.data.cli_rg
                  this.orgaoexped = res.data.cli_oe
                  this.uforgaoexped = res.data.cli_ufoe
                  this.cep = res.data.cli_cep
                  this.bairro = res.data.cli_bai
                  this.cidade = res.data.cli_cid
                  this.endereco = res.data.cli_end 
                  this.numresidencial = res.data.cli_num
                  this.fantasia= res.data.for_fan
                  this.complemento = res.data.cli_compl
                  this.telefone = res.data.cli_fone
                  this.celular = res.data.cli_cel
                  this.email = res.data.cli_email 

                  document.getElementById("razao-input").value = this.razao;
                  document.getElementById("data-nascimento").value = dataFormatada;
                  document.getElementById("rg-input").value = this.rg;
                  document.getElementById("orgaoexped-input").value = this.orgaoexped;
                  document.getElementById("uforgaoexped-input").value = this.uforgaoexped;
                  document.getElementById("sexo-input").value = this.selectedSex;
                  document.getElementById("tipoend-input").value = this.selectedTipoend;
                  document.getElementById("logradouro-input").value = this.selectedLogradouro;
                  document.getElementById("cep-input").value = this.cep;
                  document.getElementById("bairro-input").value = this.bairro;
                  document.getElementById("cidade-input").value = this.cidade
                  document.getElementById("numero-input").value = this.numresidencial
                  document.getElementById("complemento-input").value = this.complemento
                  document.getElementById("endereco-input").value = this.endereco;
                  document.getElementById("telefone-input").value = this.formataTelefone(this.telefone);
                  document.getElementById("celular-input").value = this.formataCelular(this.celular);
                  document.getElementById("email-input").value = this.email;
                  document.getElementById("fantasia-input").value = this.fantasia;
                }else{
                  this.inclusao = true
                  console.log("cliente ainda não cadastrado!")
                }
                this.$swal.close();
            })
            .catch(e => {
              this.$swal.close();
              this.inclusao = true
              console.log(e+" - Problemas na requisição da API!!!")
            });
        }
      })
      }
      else{ 
        this.erro = true
        this.inputTouchedCnpj = true; 
        console.log("CNPJ não foi preenchido completamente!!!")
      } 
    },
    clearForm() {
    const defaultData = {
      inclusao: true,
      isDisabled: false,
      inputTouchedCnpj: false,
      inputTouchedRazao: false,
      inputTouchedDtNasc: false,
      inputTouchedSexo: false,
      inputTouchedTpEnd: false,
      inputTouchedUfEnd: false,
      inputTouchedCidade: false,
      razao: '',
      cnpj: '',
      selectedSex: '',
      orgaoexped: '',
      uforgaoexped:'',
      selectedTipoend: '',
      cep: '',
      ufendereco: '',
      endereco:'',
      cidade: '',
      complemento: '',
      numresidencial: '',
      fantasia: '',
      bairro: '',
      selectedLogradouro: '',
      telefone: '',
      celular: '',
      email: '',
      rg: '',
      date: null, 
      // adicione aqui outras variáveis do seu estado que precisam ser limpas
    }
    Object.assign(this.$data, defaultData);
    },
    updateInputRazao() {
      this.inputTouchedRazao = true;
    },
    updateInputSexo() {
      this.inputTouchedSexo = true;
    },
    updateInputDtNasc() {
      this.inputTouchedDtNasc = true;
    },
    updateInputTpEnd() {
      this.inputTouchedTpEnd = true;
    },
    updateInputUfEnd() {
      this.inputTouchedUfEnd = true;
    },
    updateInputCidade() {
      this.inputTouchedCidade = true;
    },
    isValidRazao(input) {
      return input === '' || input.length <= 4
    },
    isValidDtNasc(input) {
      return input === '' 
    },
    isValidSexo(input) {
      return input === '' 
    },
    isValidTpEnd(input) {
      return input === '' 
    },
    isValidUfEnd(input) {
      return input === '' 
    },
    isValidCidade(input){
      if(this.temCaracterEspecial(input)){
        alert("O nome da cidade não pode ter caractere especial!")
        return true
      }
      return  input === ''
    },
  },
  mounted() {
    if (document.getElementById("editor")) {
      new Quill("#editor", {
        theme: "snow", // Specify theme in configuration
      });
    }

    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
        allowHTML: true,
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true,
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true,
          },
          {
            value: "Three",
            label: "Label Three",
          },
        ],
        "value",
        "label",
        false
      );
    }

    /*Dropzone.autoDiscover = false;
    var drop = document.getElementById("dropzone");
    new Dropzone(drop, {
      url: "/file/post",
      addRemoveLinks: true,
    });*/
  },
  computed: {
    inputCnpj() {
      if (this.inputTouchedCnpj) {
        return 'form-control error';
      } else {
        return 'form-control';
      }
    },
    inputRazao() {
      if (this.inputTouchedRazao && this.isValidRazao(this.razao)) {
        return 'form-control error';
      } else {
        return 'form-control';
      }
    },
    inputDtNasc() {
      if (this.inputTouchedDtNasc && this.isValidDtNasc(this.date)) {
        return 'form-control datetimepicker error';
      } else {
        return 'form-control datetimepicker';
      }
    },
    inputSexo() {
      if (this.inputTouchedSexo && this.isValidSexo(this.selectedSex)) {
        return 'form-control error';
      } else {
        return 'form-control';
      }
    },
    inputTpEnd() {
      if (this.inputTouchedTpEnd && this.isValidTpEnd(this.selectedTipoend)) {
        return 'form-control error';
      } else {
        return 'form-control';
      }
    },
    inputUfEnd() {
      if (this.inputTouchedUfEnd && this.isValidUfEnd(this.selectedUfendereco)) {
        return 'form-control error';
      } else {
        return 'form-control';
      }
    },
    inputCidade() {
      if (this.inputTouchedCidade && this.isValidCidade(this.cidade)) {
        return 'form-control error';
      } else {
        return 'form-control';
      }
    },
  }
}
</script>  

<style>
  .form-control.error{
    border-color: red;
  }

  .form-control.datetimepicker.error{
    border-color: red;
  }
</style>
 