<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Meta Corrigida</h6>
          <hr class="my-4 horizontal dark" />

          <div class="col-12 d-flex align-items-center justify-content-center">
            <button v-on:click="pesquisar()"
              type="button"
              name="button"
              class="m-1 btn bg-gradient-primary ms-1"
            >
            <i class="fas fa-arrows-rotate"> </i> Atualizar
            </button>
          </div>
          <!--<div class="mt-0 row">
          </div>-->
          <!--GRID DE FATURAMENTO -->
          <div class="row mt-4">
            <div class="col-12">
              <grid-meta-corrigida :faturamento="faturamentoDecr" :totalizador="totalizador"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import GridMetaCorrigida from "./components/GridMetaCorrigida.vue"; 
import API_URL from "../../api.js";
import Choices from "choices.js";
import Quill from "quill";  
import { mapMutations } from "vuex"; 
import { decryptData } from '../../api.js';

export default {
  name: "vendasMetaCorrigida", 
  directives: {
  },
  components: {
    GridMetaCorrigida,
    //    VueDatePicker
  },
  data() {
    return {  
      isMobile: '',  
      faturamento: [
        {}
      ],
      faturamentoDecr:[{}],
      totalizador:[{}], 
    };
  },
  methods: { 
    ...mapMutations(["navbarMinimize","navbarMaximize", "toggleConfigurator"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    }, 
    formataData(data){
      const datae = data.toString()
      let primeiraBarra =  datae.indexOf("/");
      let dia = datae.slice(0,primeiraBarra)
      let semDia = datae.slice(primeiraBarra+1,datae.length)
      let segundaBarra = semDia.indexOf("/");
      let mes = semDia.slice(0,segundaBarra)
      let ano = semDia.slice(segundaBarra+1,semDia.length)
      return  `${ano}-${mes}-${dia}`;
    }, 
    ordenarDecr() {
      this.faturamentoDecr = [...this.faturamento];


      const elementosFiltrados = [...this.faturamento].filter(fat => fat.fathoje !== null && fat.fathoje !== 0 
        );
 
      const elementosOrdenados = [...this.faturamento].filter(fat => fat.fathoje=== null || fat.fathoje === 0
        );

      //console.log("campo: "+ campo) 
      elementosFiltrados.sort((a, b) => {
      let valorA = parseInt(a.fathoje);
      let valorB = parseInt(b.fathoje);

      if (valorA > valorB) {
        return -1;
      }
      if (valorA < valorB) {
        return 1;
      }
      return 0;
    });

      this.faturamentoDecr = elementosFiltrados.concat(elementosOrdenados); 
    },
    pesquisar(){
      //console.log(decryptData(localStorage.token));
      localStorage.token ? console.log("Existe Token") : this.$route.path.split("/authentication/signin/basic") 

      this.$swal({
      title: "pesquisando...",
      didOpen: () => {
      this.$swal.showLoading();
      var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer "+decryptData(localStorage.token));

       var requestOptions = {
           method: 'GET',
           headers: myHeaders,
           //body: raw,
           redirect: 'follow'
        };
 
      fetch(API_URL+'vendas/metacorrigidatotal', requestOptions)
       .then(response => response.json())
       .then(result => {/*console.log("result: "+result.dados),*/this.$swal.close(),this.faturamento=result.dados,this.totalizador=result.totais,this.ordenarDecr('fathoje')})
       .catch(error => console.log('error', error));
      }
    })
    },
    clearForm() {
    const defaultData = {  
      // adicione aqui outras variáveis do seu estado que precisam ser limpas
    }
    Object.assign(this.$data, defaultData);
    }, 
  },
  created() { 
    console.log("created")
  },
  mounted() {
    this.isMobile = window.innerWidth < 576 
    this.pesquisar();

    if (document.getElementById("editor")) {
      new Quill("#editor", {
        theme: "snow", // Specify theme in configuration
      });
    }

    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
        allowHTML: true,
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true,
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true,
          },
          {
            value: "Three",
            label: "Label Three",
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
  computed: { 
  }
}
</script>  

<style>
  .form-control.error{
    border-color: red;
  }

  .form-control.datetimepicker.error{
    border-color: red;
  }
</style>
 