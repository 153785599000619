<template>
  <div class="card">
    <div class="table-responsive" >
      <table id="datatable-search" class="table table-flush">
        <thead class="thead-light fixo">
          <tr>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarPorLoja()"
              :style="{background: (clicado === 'loja') ? 'white' : '',
                      borderRadius: (clicado === 'loja') ? '12px 0 0 0' : '',}"
            > 
              <!--<span :style="{ color: (clicado === 'loja') ? 'blue' : '' ,
                                      backgroundColor: (clicado === 'loja') ? 'white' : '',
                                      borderRadius: (clicado === 'loja') ? '6px' : '',
                                      padding: (clicado === 'loja') ? '4px' : '' }">-->
              <span :style="{ color: (clicado === 'loja') ? 'blue' : ''}">
                Loja <i v-if="clicado === 'loja'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarPorNome()"
              :style="{background: (clicado === 'nome') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'nome') ? 'blue' : ''}">
                Funcionário <i v-if="clicado === 'nome'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('valor')"
              :style="{background: (clicado === 'valor') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'valor') ? 'blue' : '' }">
                Valor <i v-if="clicado === 'valor'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('itens')"
              :style="{background: (clicado === 'itens') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'itens') ? 'blue' : '' }">
                Itens <i v-if="clicado === 'itens'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span> 
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('qtdev')"
              :style="{background: (clicado === 'qtdev') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'qtdev') ? 'blue' : '' }">
                qtdev <i v-if="clicado === 'qtdev'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('dcv')"
              :style="{background: (clicado === 'dcv') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'dcv') ? 'blue' : '' }">
                dcv <i v-if="clicado === 'dcv'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('itensporvenda')"
              :style="{background: (clicado === 'itensporvenda') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'itensporvenda') ? 'blue' : '' }">
                it/vda <i v-if="clicado === 'itensporvenda'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              :style="{background: (clicado === 'horas') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'horas') ? 'blue' : '' }">
                Horas 
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              :style="{background: (clicado === 'vlrhora') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'vlrhora') ? 'blue' : '' }">
                vlrhora 
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('vlrv')"
              :style="{background: (clicado === 'vlrv') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'vlrv') ? 'blue' : '' }">
                valor/venda <i v-if="clicado === 'vlrv'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('custo')"
              :style="{background: (clicado === 'custo') ? 'white' : ''}"
            >
              <span :style="{ color: (clicado === 'custo') ? 'blue' : '' }">
                custo <i v-if="clicado === 'custo'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('totalv')" 
              :style="{background: (clicado === 'totalv') ? 'white' : ''}"
            > 
              <span :style="{ color: (clicado === 'totalv') ? 'blue' : '' }">
                totalfat <i v-if="clicado === 'totalv'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
            <th v-bind:class="{ 'pointer-cursor': isMouseOverTitle }" @mouseover="isMouseOverTitle = true"
              @mouseleave="isMouseOverTitle = false"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              v-on:click="ordenarDecr('margem')" 
              :style="{background: (clicado === 'margem') ? 'white' : '',
                      borderRadius: (clicado === 'margem') ? '0 12px 0 0' : ''}"
            > 
              <span :style="{ color: (clicado === 'margem') ? 'blue' : '' }">
                margem <i v-if="clicado === 'margem'" class='fas fa-chevron-up'></i>
                      <i v-else  class='fas fa-chevron-down'></i>
              </span>
            </th>
          </tr>
        </thead>

        <!--ordenacao padrão-->
        <tbody v-if="this.ordenacao === 'padrao'" >
          <tr v-for="(des,index) in desempenhoVend" :key="index"
             v-on:click="selecionado(index)" >
              <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.loja }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.apelido }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.valor) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.itens }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.qtdev }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.dcv }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.itensporvenda) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.horas }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.vlrhora }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.vlrv) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.custo) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.totalv) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.margem) }}</h6>
                  </div>
                </div>
              </td>
          </tr>
        </tbody>
        <!--FIM ORDENAÇÃO PADRAO--> 

        <!--ordenacao loja-->
        <tbody v-if="this.ordenacao === 'loja'" >
            <tr v-for="(des,index) in desempenhoLoja" :key="index"
             v-on:click="selecionado(index)" >
              <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.loja }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.apelido }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.valor) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.itens }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.qtdev }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.dcv }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.itensporvenda) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.horas }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.vlrhora }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.vlrv) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.custo) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.totalv) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.margem) }}</h6>
                  </div>
                </div>
              </td>
            </tr>
        </tbody>
        <!--FIM ORDENAÇÃO LOJA-->

        <!--ordenacao nome-->
        <tbody v-if="this.ordenacao === 'nome'" >
            <tr v-for="(des,index) in desempenhoNome" :key="index"
             v-on:click="selecionado(index)" >
              <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.loja }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.apelido }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.valor) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.itens }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.qtdev }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.dcv }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.itensporvenda) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.horas }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.vlrhora }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.vlrv) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.custo) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.totalv) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.margem) }}</h6>
                  </div>
                </div>
              </td>
            </tr>
        </tbody>
        <!--FIM ORDENAÇÃO NOME-->

        <!--ordenacao OUTRO-->
        <tbody v-if="this.ordenacao === 'outro'" >
          <tr v-for="(des,index) in desempenhoDecr" :key="index"
             v-on:click="selecionado(index)" >
              <td >
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.loja }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.apelido }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.valor) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.itens }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.qtdev }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.dcv }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.itensporvenda) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.horas }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ des.vlrhora }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.vlrv) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.custo) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.totalv) }}</h6>
                  </div>
                </div>
              </td>
              <td>
                <div class="align-middle text-center">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(des.margem) }}</h6>
                  </div>
                </div>
              </td>
          </tr>
        </tbody>
        <!--FIM ORDENAÇÃO OUTRO-->
        
        <!--TOTALIZADORES--------------------------->
        <!--<tbody>
          <tr>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">TOTAL</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ totalItens }}</h6>
                </div>
              </div>
            </td>
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{ totalValor }}</h6>
                </div>
              </div>
            </td>
          </tr>
        </tbody>-->
      </table>
    </div>
  </div>
</template>


<script>
import '@fortawesome/fontawesome-free/css/all.css' 
//import API_URL from "../../../api.js"
//import { decryptData } from '../../../api.js';
//import { DataTable } from "simple-datatables";

export default {
  name: "GridVendedorDesempenho",
  props: {
    desempenhoVend: {
      type: Array,
      required: true
    }, 
  },
  components: {
  
  },
  data() {
    return {
      vendasclass: [{}],
      ordenacao: 'padrao',
      clicado: 'loja',
      registroSelecionado: '',
      desempenhoLoja: '',
      desempenhoDecr: '', 
      desempenhoNome: '',
      isMouseOverTitle: false,
    };
  },
  methods: {
    selecionado(index) {
      //console.log("Registro selecionado: "+index)
      this.registroSelecionado = index;
    },
    ordenarPorLoja() {
      this.ordenacao = 'loja'
      this.clicado = 'loja'
      
      this.desempenhoLoja = [...this.desempenhoVend];

      this.desempenhoLoja.sort((a, b) => {
      let lojaA = a.loja.toLowerCase();
      let lojaB = b.loja.toLowerCase();

      if (lojaA.length < 2) {
        lojaA = lojaA.toString().padStart(2, '0');
      }
      if (lojaB.length < 2) {
        lojaB = lojaB.toString().padStart(2, '0');
      }

      if (lojaA < lojaB) {
        return -1;
      }
      if (lojaA > lojaB) {
        return 1;
      }
      return 0;
    });

    console.log("desempenhoLoja: "+this.desempenhoLoja)
    },  
    ordenarPorNome() {
      this.ordenacao = 'nome'
      this.clicado = 'nome'
      
      this.desempenhoNome = [...this.desempenhoVend];

      this.desempenhoNome.sort((a, b) => {
      let apelidoA = a.apelido.toLowerCase();
      let apelidoB = b.apelido.toLowerCase();

      if (apelidoA < apelidoB) {
        return -1;
      }
      if (apelidoA > apelidoB) {
        return 1;
      }
      return 0;
    });

    console.log("desempenhoNome: "+this.desempenhoNome)
    },   
    ordenarDecr(campo) {
      this.ordenacao = 'outro'
      if(campo === 'valor'){
        this.clicado = 'valor'
      }else if (campo === 'itens'){
        this.clicado = 'itens'
      }else if (campo === 'qtdev'){
        this.clicado = 'qtdev'
      }else if (campo === 'dcv'){
        this.clicado = 'dcv'
      }else if (campo === 'itensporvenda'){
        this.clicado = 'itensporvenda'
      }else if (campo === 'horas'){
        this.clicado = 'padrao'
      }else if (campo === 'vlrhora'){
        this.clicado = 'padrao'
      }else if (campo === 'vlrv'){
        this.clicado = 'vlrv'
      }else if (campo === 'custo'){
        this.clicado = 'custo'
      }else if (campo === 'totalv'){
        this.clicado = 'totalv'
      }else if (campo === 'margem'){
        this.clicado = 'margem'
      }else{
        this.clicado = 'padrao'
      }

      this.desempenhoDecr = [...this.desempenhoVend];

      const elementosFiltrados = [...this.desempenhoVend].filter(fat => fat[campo] !== null && fat[campo] !== 0);
 
      const elementosOrdenados = [...this.desempenhoVend].filter(fat => fat[campo] === null || fat[campo] === 0);

      console.log("campo: "+ campo) 
      elementosFiltrados.sort((a, b) => {
      let valorA = parseInt(a[campo]);
      let valorB = parseInt(b[campo]);

      if (valorA > valorB) {
        return -1;
      }
      if (valorA < valorB) {
        return 1;
      }
      return 0;
      });

      this.desempenhoDecr = elementosFiltrados.concat(elementosOrdenados); 
    }, 
    formataData(data){
      const datae = data.toString()
      let primeiraBarra =  datae.indexOf("/");
      let dia = datae.slice(0,primeiraBarra)
      let semDia = datae.slice(primeiraBarra+1,datae.length)
      let segundaBarra = semDia.indexOf("/");
      let mes = semDia.slice(0,segundaBarra)
      let ano = semDia.slice(segundaBarra+1,semDia.length)
      return  `${ano}-${mes}-${dia}`;
    },
    formataValor(valorOriginal) {
      let valorFormatado = Number(valorOriginal).toFixed(2);
      valorFormatado = valorFormatado.replace('.', ',');
      const numero = parseFloat(valorFormatado.replace(",", "."));
      const numeroFormatado = numero.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
      return numeroFormatado ;
    },
    heightMobile() {
      return window.innerWidth > 412 ? true : false
    }
  },
  watch: {
    desempenhoVend: {
      immediate: true,
      handler() {
        this.ordenacao = 'padrao';
        this.clicado = 'loja';
        },
    },
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    /*const dataTableSearch = new DataTable("#datatable-search", {
      searchable: true,
      fixedHeight: false,
      language: {
        search: "Buscar:",  
      }
    });*/
  },
};
</script>

<style>
  .fas.fa-trash-alt {
    color: red;
  }

  .pointer-cursor{
    cursor: pointer;
  }

  thead.fixo {
    position: sticky;
    top: 0;
    z-index: 999; /* Isso é opcional, se você tiver algum elemento com um z-index maior que sobreponha o cabeçalho, você pode aumentar esse valor */
  }
</style>
