<template>
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Rastreado
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Genérico
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Descr. do Produto
            </th>
            <th
              class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
            >
              Referência
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Cor
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Taman.
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              R$ Venda
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="produto in produtos" :key="produto.p_ras">
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{produto.p_ras}}</span>
            </td>
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{produto.p_gen}}</h6>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{produto.p_desc}}</p>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">{{produto.p_ref}}</p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">{{produto.p_cor}}</p>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{produto.p_tam}}</span>
            </td>
            <td>
              <div class="d-flex px-2 py-1">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{produto.p_prevenda}}</h6>
                </div>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</template>

<script> 

export default {
  name: "GridAdicionaRastreado",
  props: {
    produtos: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data() {
    return {
    };
  },
};
</script>
