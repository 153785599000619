export default {
    methods: {
      formataValor(valorOriginal) {
        if(valorOriginal === null || valorOriginal === ''){
          return null
        }
        let valorFormatado = Number(valorOriginal).toFixed(2);
        valorFormatado = valorFormatado.replace('.', ',');
        const numero = parseFloat(valorFormatado.replace(",", "."));
        const numeroFormatado = numero.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        return (numeroFormatado === null || numeroFormatado === '') ? null : numeroFormatado;
      },
      formataValorInt(valorOriginal) {
          const numeroFormatado = valorOriginal.toLocaleString('pt-BR', { minimumFractionDigits: 0 });
        return numeroFormatado;
      },
    }
  };