<template> 
  <div class="card">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              
            > 
              Departamento
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8"
              
            > 
              Operação
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 vlr-custom-width"
              
            > 
              Total
            </th>
            <th  v-if="lojas && lojas[0]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 0-->
              LJ {{ this.lojas[0].nome }}
            </th>
            <th  v-if="lojas && lojas[1]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 1-->
              LJ {{ this.lojas[1].nome }}
            </th>
            <th  v-if="lojas && lojas[2]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 2-->
              LJ {{ this.lojas[2].nome }}
            </th>
            <th  v-if="lojas && lojas[3]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 3-->
              LJ {{ this.lojas[3].nome }}
            </th>
            <th  v-if="lojas && lojas[4]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 4-->
              LJ {{ this.lojas[4].nome }}
            </th>
            <th  v-if="lojas && lojas[5]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 5-->
              LJ {{ this.lojas[5].nome }}
            </th>
            <th v-if="lojas && lojas[6]" 
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 6-->
              LJ {{ this.lojas[6].nome }}
            </th>
            <th v-if="lojas && lojas[7]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 7-->
              LJ {{ this.lojas[7].nome }}
            </th>
            <th v-if="lojas && lojas[8]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 8-->
              LJ {{ this.lojas[8].nome }}
            </th>
            <th v-if="lojas && lojas[9]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 9-->
              LJ {{ this.lojas[9].nome }}
            </th>
            <th v-if="lojas && lojas[10]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 10-->
              LJ {{ this.lojas[10].nome }}
            </th>
            <th v-if="lojas && lojas[11]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 11-->
              LJ {{ this.lojas[11].nome }}
            </th>
            <th v-if="lojas && lojas[12]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 12-->
              LJ {{ this.lojas[12].nome }}
            </th>
            <th v-if="lojas && lojas[13]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 13-->
              LJ {{ this.lojas[13].nome }}
            </th>
            <th v-if="lojas && lojas[14]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 14-->
              LJ {{ this.lojas[14].nome }}
            </th>
            <th v-if="lojas && lojas[15]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 15-->
              LJ {{ this.lojas[15].nome }}
            </th>
            <th v-if="lojas && lojas[16]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 16-->
              LJ {{ this.lojas[16].nome }}
            </th>
            <th v-if="lojas && lojas[17]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 17-->
              LJ {{ this.lojas[17].nome }}
            </th>
            <th v-if="lojas && lojas[18]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 18-->
              LJ {{ this.lojas[18].nome }}
            </th>
            <th v-if="lojas && lojas[19]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 19-->
              LJ {{ this.lojas[19].nome }}
            </th>
            <th v-if="lojas && lojas[20]"
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-8 custom-width"
            >
              <!--LJ 20-->
              LJ {{ this.lojas[20].nome }}
            </th>
          </tr>
        </thead>
 

        <!--ORDENAÇÃO PADRÃO-->
        <tbody>
          <tr v-for="(fat, index) in faturamento" :key="index" v-on:click="selecionado(index)">
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{fat.descricao}}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{fat.operacao }}</h6>
                </div>
              </div>
            </td>  
            <td>
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.total) }}</h6>
                </div>
              </div>
            </td> 

            <td v-if="lojas && lojas[0]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l1) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[1]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l2) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[2]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l3) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[3]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l4) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[4]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l5) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[5]"> 
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l6) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[6]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l7) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[7]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l8) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[8]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l9) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[9]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l10) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[10]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l11) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[11]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l12) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[12]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l13) }}</h6>
                </div>
              </div>
            </td> 
            <td  v-if="lojas && lojas[13]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l14) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[14]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l15) }}</h6>
                </div>
              </div>
            </td> 
            <td  v-if="lojas && lojas[15]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l16) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[16]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l17) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[17]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l18) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[18]">
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l19) }}</h6>
                </div>
              </div>
            </td> 
            <td v-if="lojas && lojas[19]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l20) }}</h6>
                </div>
              </div>
            </td>  
            <td v-if="lojas && lojas[20]" >
              <div class="align-middle text-center">
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm" :style="{color:(registroSelecionado == index)?'#00CC00':''}">{{ this.formataValor(fat.l21) }}</h6>
                </div>
              </div>
            </td>  
          </tr>
        </tbody> 
        <!--FIM ORDERNAÇÃO PADRÃO-->
 

      </table>
    </div> 
  </div>
</template>

<script> 
import '@fortawesome/fontawesome-free/css/all.css' 
import { format } from 'date-fns';

export default {
  name: "GridDepartamento",
  props: {
    faturamento: {
      type: Array,
      required: true
    },
    lojas: {
      type: Object,
      required: true
    }
  },
  data() {
    return {   
      ordenacao: 'padrao',
      faturamentoLoja: '',
      faturamentoDecr: '',
      isMouseOverTitle: false,
      registroSelecionado: '',
      datai: format(new Date(),'dd/MM/yyyy'),
      datah: new Date()
    }
  },
  components: {
    //CardVendasClass
  },
  methods: {
    selecionado(index) {
      //console.log("Registro selecionado: "+index)
      this.registroSelecionado = index;
    },
    validaLoja(){
      return this.lojas.length
    },
  }, 
  watch: {
    faturamento: {
      immediate: true,
      handler() {
        this.ordenacao = 'padrao';
        this.clicado = 'fathoje';
        },
    },
  },
  computed: { 
  },  
  created(){
  },
};
</script>
<style>

</style>
