<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0" id="titulo">{{ 'Orçamento: ' + this.orca }}</h6>
          <hr class="my-4 horizontal dark" />

          <!--PRIMEIRA LINHA-->
          <div class="mt-2 row">
            <div class="col-5">
              <label for="projectName" class="form-label">Rastreado</label>
              <input id="rastreado-input" type="text" class="form-control"  v-mask="'################'"
              v-model="rastreado" @blur="validaRas"/>
            </div>
            <div class="mt-4 d-flex justify-content-start">
              <button v-on:click="adicionar()"
                type="button"
                name="button"
                class="m-0 btn bg-gradient-success ms-2"
              >
              +
              </button>
            </div>
          </div>
          <hr class="my-4 horizontal dark" />

          <!--GRID RASTREADOS-->
          <div class="row mt-4">
            <div class="col-12">
              <grid-adiciona-rastreado :produtos="produtos"/>
            </div>
          </div>

 
          <div class="mt-4 d-flex justify-content-end">
            <button type="button" name="button" class="m-0 btn btn-light" v-on:click="clearForm()">
              Cancelar
            </button>
            <button v-on:click="finalizar()"
              type="button"
              name="button"
              class="m-0 btn bg-gradient-success ms-2"
            >
            Gravar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import Choices from "choices.js";
//import API_URL from '../../api.js'
import Quill from "quill"; 
import {mask} from 'vue-the-mask'
import GridAdicionaRastreado from "./components/GridAdicionaRastreado.vue";


export default {
  name: "NewProject", 
  directives: {
    mask
  },
  components: {
    GridAdicionaRastreado,
  },
  data() {
    return {
      orca: '',
      inclusao: true,
      isDisabled: false, 
      indice: 0,
      loja: '', 
      nome: '',
      vendedor: '',
      rastreado: '',
      generico: '',
      descricao: '',
      cor: '',
      tamanho:'',
      preco: '', 
      date: '', 
      produtos: [
      {
        p_ras: '',
        p_gen: '',
        p_desc: '',
        p_prevenda: '',
        p_cor: '',
        p_tam: '',
        p_ref: ''
      }
    ],
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y'
      },
    };
  },
  created(){
    if(localStorage.orca != undefined || localStorage.orca != null){
      this.orca = localStorage.orca 
    }else{
      this.orca = 0
    }
  }, 
  filters: {
    upperCase(value) {
      return value.toUpperCase();
    }
  },
  watch: {
    /*rastreado: function(newValor) {
      this.rastreado = newValor == null ? " " : newValor.toString().padStart(16, '0');
    },*/
  },
  methods: { 
    camposObrigatorios(){
    },
    formataValor(valor){
      const valorFormatado = valor.toFixed(2).replace('.', ',');
      return valorFormatado;
    },
    adicionar(){
      console.log("adicionar")
    },
    finalizar(){
      console.log("finalizar")
    },
    verificaRastreado(ras){
      if (this.produto.p_ras.includes(ras)) {
        return true
      }else{
        console.log("Rastreado Ok")
        return false
      }
    },
    validaRas() { 
      if(this.rastreado.length < 16){
        this.rastreado = (this.rastreado).toString().padStart(16, '0')
      } 
      this.isDisabled = true
      this.$swal({
        title: "verificando Rastreado...",
        didOpen: () => {
          this.$swal.showLoading(); 
          this.$http
            //.get('https://api.raro-srv.com/api/client/'+this.cpf,{headers: {
            .get('http://10.12.0.49:8000/api/rastreado/'+this.rastreado,{headers: {
                  "Content-Type": "application/json",
                    Authorization: "Bearer "+localStorage.getItem('token')}}) 
            .then((response) => { 
                let res = JSON.stringify(response.data.data[0])
                if (res == undefined || response.data.data.sts == "erro"){
                  console.log("Motivo erro: "+JSON.stringify(response.data.data.mensagem))
                  const mensagem = JSON.stringify(response.data.data.mensagem)
                  this.$swal({ 
                    icon: "warning",
                    text: mensagem,
                    confirmButtonText: "Ok",
                    customClass: {
                      confirmButton: "btn bg-gradient-danger"
                    },
                    buttonsStyling: false,
                  });
                }
                else{                       
                  //INSERINDO RASTREADO EM ORÇAMENTO 
                    const novoProduto = {
                      p_ras: this.rastreado,
                      p_gen: JSON.stringify(response.data.data[0].pro_gen),
                      p_desc: JSON.stringify(response.data.data[0].gen_des),
                      p_prevenda: JSON.stringify(response.data.data[0].vlrvenda),
                      p_cor: JSON.stringify(response.data.data[0].gen_cor),
                      p_tam: JSON.stringify(response.data.data[0].gen_tam),
                      p_ref: JSON.stringify(response.data.data[0].gen_ref)
                  }; 
                  this.produtos.push(novoProduto) 
                  console.log('this.produto:  '+JSON.stringify(this.produtos))
                  
                  this.$http 
                  .patch('http://10.12.0.49:8000/api/orcamento/'+localStorage.getItem('orca'),{
                    rastreado: this.rastreado
                  },
                  {headers: {
                        "Content-Type": "application/json",
                          Authorization: "Bearer "+localStorage.getItem('token')}}) 
                  .then((response) => {
                      console.log("-----------------------------"+response) 

                  })
                  .catch(e => {  
                    console.log(e+" - Problemas na adicionar Rastreado no Orçamento!!!")
                  }); 
                }      
                  this.$swal.close();           
            })
            .catch(e => {
              this.$swal.close();
              this.inclusao = true
              console.log(e+" - Problemas para validar Rastreado!!!")
            });
        }
      })
    },
    clearForm() {
    const defaultData = {
      inclusao: true,
      isDisabled: false,
      inputTouchedLoja: false,
      inputTouchedNome: false,
      inputTouchedData: false,
      inputTouchedVendedor: false,
      inputTouchedRastreado: false,
      inputTouchedGenerico: false,
      inputTouchedDescricao: false,
      loja: "", 
      nome: "",
      vendedor: "",
      rastreado: "",
      generico: "",
      descricao: '',
      cor: '',
      tamanho:'',
      preco: '', 
      date: '', 
      produtos: [
      {
        p_ras: '',
        p_gen: '',
        p_desc: '',
        p_prevenda: '',
        p_cor: '',
        p_tam: '',
        p_ref: '',
      }
    ],
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y'
      },
      // adicione aqui outras variáveis do seu estado que precisam ser limpas
    }
    Object.assign(this.$data, defaultData);
    },
    updateInputRastreado() {
      this.inputTouchedRastreado = true;
    },
  },
  mounted() {
    if (document.getElementById("editor")) {
      new Quill("#editor", {
        theme: "snow", // Specify theme in configuration
      });
    }

    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
        allowHTML: true,
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true,
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true,
          },
          {
            value: "Three",
            label: "Label Three",
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
  computed: {
    inputRastreado() {
      if (this.inputTouchedRastreado) {
        return 'form-control error';
      } else {
        return 'form-control';
      }
    },
  }
}
</script>  

<style>
  .form-control.error{
    border-color: red;
  }

  .form-control.datetimepicker.error{
    border-color: red;
  }
</style>
 