<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Departamento</h6>
          <hr class="my-4 horizontal dark" />

          <!--Periodo-->
          <div v-if="isMobile" class="mt-0 row">
            <div class="col-12">
              <label class="form-label">Data Inicial</label>
              <flat-pickr id="data-inicial" v-model="datai" class="form-control datetimepicker"
                placeholder="--/--/----" :config="config"></flat-pickr>
            </div>
            <div class="col-12">
              <label class="form-label">Data Final</label>
              <flat-pickr id="data-final" v-model="dataf" class="form-control datetimepicker"
                placeholder="--/--/----" :config="config"></flat-pickr>
            </div>
            <div class="col-12 d-flex align-items-center justify-content-center">
              <button v-on:click="pesquisar()"
                type="button"
                name="button"
                class="m-4 btn bg-gradient-primary ms-1"
              >
              <i class="fas fa-magnifying-glass"> </i> Pesquisar
              </button>
            </div>
          </div>
          <!--FIM DIV-->

          <!--Periodo 2-->
          <div v-else class="mt-0 row">
            <div class="col-4">
              <label class="form-label">Data Inicial</label>
              <flat-pickr id="data-inicial" v-model="datai" class="form-control datetimepicker"
                placeholder="--/--/----" :config="config"></flat-pickr>
            </div>
            <div class="col-4">
              <label class="form-label">Data Final</label>
              <flat-pickr id="data-final" v-model="dataf" class="form-control datetimepicker"
                placeholder="--/--/----" :config="config"></flat-pickr>
            </div>
            <div class="col-4 d-flex align-items-center justify-content-center">
              <button v-on:click="pesquisar()"
                type="button"
                name="button"
                class="m-3 btn bg-gradient-primary ms-4"
              >
              <i class="fas fa-magnifying-glass"> </i>  Pesquisar
              </button>
            </div>
          </div>
          <!--FIM DIV-->

          <!--GRID DE FATURAMENTO -->
          <div class="row mt-3">
            <div class="col-12">
              <grid-departamento :faturamento="faturamentoDecr" :lojas="lojas"/>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js"; 
import GridDepartamento from "./components/GridDepartamento.vue"; 
import API_URL from "../../api.js"
import Choices from "choices.js";
import Quill from "quill"; 
import {mask} from 'vue-the-mask';
import { mapMutations } from "vuex";
import { format } from 'date-fns';
import { decryptData } from '../../api.js';

export default {
  name: "VendasDepartamento", 
  directives: {
    mask
  },
  components: {
    GridDepartamento,
    flatPickr,
//    VueDatePicker
  },
  data() {
    return {  
      isMobile: '',
      novoOrca: '',
      orcamentos: [
        {}
      ],
      faturamento: [
        {}
      ],
      lojas: [{}],
      faturamentoDecr:[{}],
      totalizador:[
        {}
      ],
      datai: '',
      dataf: '',
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y',
        defaultDate: new Date()
      }
    };
  },
  methods: {
    ...mapMutations(["navbarMinimize","navbarMaximize", "toggleConfigurator"]),
    toggleNavigationOnMobile() {
      if (window.innerWidth < 1200) {
        this.navbarMinimize();
      }
    }, 
    formataData(data){
      const datae = data.toString()
      let primeiraBarra =  datae.indexOf("/");
      let dia = datae.slice(0,primeiraBarra)
      let semDia = datae.slice(primeiraBarra+1,datae.length)
      let segundaBarra = semDia.indexOf("/");
      let mes = semDia.slice(0,segundaBarra)
      let ano = semDia.slice(segundaBarra+1,semDia.length)
      return  `${ano}-${mes}-${dia}`;
    }, 
    ordenarDecr() {
      const elementos = [...this.faturamento];

      elementos.sort((a, b) => {
        let valorA = parseInt(a.depto);
        let valorB = parseInt(b.depto);

          if (valorA < valorB) {
            return -1;
          }
          if (valorA > valorB) {
            return 1;
          }
          return 0;
      });

      this.faturamentoDecr = elementos; 
    },
    pesquisar(){
      //console.log(decryptData(localStorage.token));
      localStorage.token ? console.log("Existe Token") : this.$route.path.split("/authentication/signin/basic") 

      this.$swal({
      title: "pesquisando...",
      didOpen: () => {
      this.$swal.showLoading();
      var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", "Bearer "+decryptData(localStorage.token));

       var requestOptions = {
           method: 'GET',
           headers: myHeaders,
           //body: raw,
           redirect: 'follow'
        };

      console.log("datai: "+this.formataData(this.datai)+" dataf: "+this.formataData(this.dataf))
      fetch(API_URL+'vendas/departamento?datai='+this.formataData(this.datai)+'&dataf='+this.formataData(this.dataf), requestOptions)
       .then(response => response.json())
       .then(result => {/*console.log(result),*/this.$swal.close(),this.faturamento=result.dados,this.lojas=result.lojas,this.ordenarDecr()})
       .catch(error => console.log('error', error));

      }
    })
    },
    clearForm() {
    const defaultData = {  
      orcamentos: [
        {}
      ],
      config: {
        allowInput: true,
        locale: 'pt',
        dateFormat: 'd/m/Y'
      },
      // adicione aqui outras variáveis do seu estado que precisam ser limpas
    }
    Object.assign(this.$data, defaultData);
    }, 
  },
  created() { 
    console.log("created")
  },
  mounted() {
    this.isMobile = window.innerWidth < 576
    this.datai = format(new Date(),'dd/MM/yyyy')
    this.dataf = format(new Date(),'dd/MM/yyyy')
    this.pesquisar();

    if (document.getElementById("editor")) {
      new Quill("#editor", {
        theme: "snow", // Specify theme in configuration
      });
    }

    if (document.getElementById("choices-multiple-remove-button")) {
      var element = document.getElementById("choices-multiple-remove-button");
      const example = new Choices(element, {
        removeItemButton: true,
        allowHTML: true,
      });

      example.setChoices(
        [
          {
            value: "One",
            label: "Label One",
            disabled: true,
          },
          {
            value: "Two",
            label: "Label Two",
            selected: true,
          },
          {
            value: "Three",
            label: "Label Three",
          },
        ],
        "value",
        "label",
        false
      );
    }
  },
  computed: { 
  }
}
</script>  

<style>
  .form-control.error{
    border-color: red;
  }

  .form-control.datetimepicker.error{
    border-color: red;
  }
</style>
 